import { defineStore } from 'pinia'

interface ModalRootState {
  initialised: boolean
  modal: Modal

  column: ColumnID | 0
  dependencies: Record<string, any>
}

export const useModalStore = defineStore({
  id: 'modal',

  state: (): ModalRootState => ({
    initialised: true,
    modal: 'none',

    // States
    column: 0,
    dependencies: {} as Record<string, any>,
  }),

  actions: {
    updateInitialised(initValue: boolean) {
      this.initialised = initValue
    },
    open(modal: Modal, dependencies: Record<string, any> = {}) {
      if (modal && this.modal !== modal) {
        this.modal = modal
        if (dependencies)
          this.dependencies = dependencies
      }
    },
    close() {
      this.modal = 'none'

      this.column = 0
    },
  },

  getters: {
    getInitialised: state => state.initialised,
    isModal: state => (modal: Modal) => modal && state.modal === modal,
  },
})
