import { defineStore } from 'pinia'
import GlobalPanels from '@/models/Panels.js'

interface PanelsRootState {
  initialised: boolean
  colours: Record<number, string>
  panels: Record<'infatuation' | 'resentment', Record<'self' | 'other', Record<number, Panel>>>
}

export const usePanelsStore = defineStore({
  id: 'idpanels',

  state: (): PanelsRootState => ({
    initialised: true,
    colours: {
      1: '#252525',
      2: '#866EAA',
      3: '#95328A',
      4: '#B42B30',
      5: '#EA973E',
      6: '#FADD4B',
      7: '#91C69F',

      8: '#252525',
      9: '#866EAA',
      10: '#95328A',
      11: '#B42B30',
      12: '#EA973E',
      13: '#FADD4B',
      14: '#91C69F',
    },
    panels: {
      infatuation: {
        self: {},
        other: {},
      },
      resentment: {
        self: {},
        other: {},
      },
    },
  }),

  actions: {
    initialisePanels(sessionfocus: SessionFocus) {
      this.panels = {
        infatuation: {
          ...GlobalPanels.infatuation[sessionfocus],
        },
        resentment: {
          ...GlobalPanels.resentment[sessionfocus],
        },
      }
    },
    updateInitialised(initValue: boolean) {
      this.initialised = initValue
    },
  },

  getters: {
    getInitialised: state => state.initialised,
    getColourByColumnID: state => (columnID: number) => state.colours[columnID],
    getActivePanel: state => (request: {
      type: SessionType
      column: ColumnID
    }) => {
      return state.panels[request.type][request.column]
    },
    getQuestion: state => (request: {
      side: SessionSide
      column: ColumnID
    }) => {
      if (request.side && request.column) {
        const activePanel = state.panels[request.side][request.column]

        if ('instruction' in activePanel && activePanel.instruction)
          return activePanel.instruction

        else
          return ''
      }
      else {
        return ''
      }
    },
  },
})
