import { defineStore } from 'pinia'

import { useAppStore } from '@/stores/app'
import { usePanelsStore } from '@/stores/panels'

import { AE, AEL, KIO, KIOE, clearLocalStorageWithKeyContaining, hasSpaces, strFirstLetters } from '@/utility/utility'

import type { UserRecord } from '@/types/api/user'

import { useModalStore } from '@/stores/modal'

import { useUserRecordStore } from '@/stores/userrecord'

import { useAuthStore } from '@/stores/auth'

const blankColumnRecordA = {
  2: { e: 0, t: 0, s: 'open' },
  3: { e: 0, t: 0, s: 'open' },
  4: { e: 0, t: 0, s: 'open' },
  5: { e: 0, t: 0, s: 'open' },
  6: { e: 0, t: 0, s: 'open' },
  7: { e: 0, t: 0, s: 'open' },
}
const blankColumnRecordB = {
  9: { e: 0, t: 0, s: 'open' },
  10: { e: 0, t: 0, s: 'open' },
  11: { e: 0, t: 0, s: 'open' },
  12: { e: 0, t: 0, s: 'open' },
  13: { e: 0, t: 0, s: 'open' },
  14: { e: 0, t: 0, s: 'open' },
}

const blankRecord = {
  sessionComplete: false,
  sessionStagingComplete: false,
  taisTotal: 0,
  taisCompleted: 0,
  taisRemaining: 0,
  percComplete: 0,
  columns: {
    total: 0,
    colsComplete: 0,
    colsRemaining: 0,
    percComplete: 0,
  },

  infatuation: {
    complete: false,
    taisTotal: 0,
    taisCompleted: 0,
    taisRemaining: 0,
    percComplete: 0,
    columns: {
      total: 0,
      colsComplete: 0,
      colsRemaining: 0,
      percComplete: 0,
    },
  },
  resentment: {
    complete: false,
    taisTotal: 0,
    taisCompleted: 0,
    taisRemaining: 0,
    percComplete: 0,
    columns: {
      total: 0,
      colsComplete: 0,
      colsRemaining: 0,
      percComplete: 0,
    },
  },
}

interface SessionRootState {
  initialised: boolean
  session: {
    id: string
    type: SessionType
    focus: SessionFocus

    individual: {
      name: string
      relation: string
    }
  }
  traits: Trait[]
  sides: Record<'traits' | 'overview', SessionSide>

  trait: Trait
  column: ColumnID
  unlockMode: boolean

  // State =====================================================================

  // Global Columns state
  columns: TraitColumnState[]
  columnID: ColumnID | 0

  // Local State
  // entries: String[]
  time: number

  // Session Values
  values: string[]

  // Column Entries
  entries: string[]
  scratches: string
  scratch: string

  // Session Record =====================================================================
  record: InSessionRecord

  // Session states
  taisChecklistComplete: boolean
}

export const useSessionStore = defineStore({
  id: 'sessionstore',

  state: (): SessionRootState => ({

    initialised: false,
    session: {
      id: 'session123',
      type: 'infatuation',
      focus: 'other',

      individual: {
        name: 'John',
        relation: 'partner',
      },
    },

    traits: [],

    sides: {
      traits: 'infatuation',
      overview: 'infatuation',
    },

    // Active Trait =====================================================================
    trait: {
      id: -1,
      index: -1,
      label: '',
      side: 'infatuation',
    },
    // Active column
    column: 2,

    unlockMode: false,

    // State =====================================================================
    time: 0,

    // Columns Record & State
    columns: [],
    columnID: 0,

    // Session Values
    values: [],

    // Column Entries
    entries: [],
    scratch: '',
    scratches: '',

    // Record & completion =====================================================================
    record: {
      ...blankRecord,
    },

    // Session states
    taisChecklistComplete: false,

  }),

  actions: {
    logout() {
      const router = useRouter()
      this.$reset()

      const sessionID = useCookie('session_id')
      sessionID.value = null

      // alert('Session logout redirect')
      router.push('/')
    },
    processRecord() {
      const record = {
        sessionComplete: false,
        sessionStagingComplete: false,
        taisTotal: 0,
        taisCompleted: 0,
        taisRemaining: 0,
        percComplete: 0,
        columns: {
          total: 0,
          colsComplete: 0,
          colsRemaining: 0,
          percComplete: 0,
        },
        infatuation: {
          complete: false,
          taisTotal: 0,
          taisCompleted: 0,
          taisRemaining: 0,
          percComplete: 0,
          columns: {
            total: 0,
            colsComplete: 0,
            colsRemaining: 0,
            percComplete: 0,
          },
        },
        resentment: {
          complete: false,
          taisTotal: 0,
          taisCompleted: 0,
          taisRemaining: 0,
          percComplete: 0,
          columns: {
            total: 0,
            colsComplete: 0,
            colsRemaining: 0,
            percComplete: 0,
          },
        },
      }

      const columns = this.columns
      if (columns.length === 0)
        return record

      columns.forEach((cState) => {
        // Traits totals ====================================================================
        record.taisTotal += 1

        if (cState.side === 'infatuation')
          record.infatuation.taisTotal += 1

        else
          record.resentment.taisTotal += 1

        // Completion ====================================================================
        const columnStates = Object.values(cState.cols)

        const side = cState.side
        let taiComplete = true
        columnStates.forEach((colState) => {
          record.columns.total += 1
          record[side].columns.total += 1

          if (colState.s !== 'complete') {
            taiComplete = false
            record.columns.colsRemaining += 1
            record[side].columns.colsRemaining += 1
          }
          else {
            record.columns.colsComplete += 1
            record[side].columns.colsComplete += 1
          }
        })

        // Trait Totals
        if (taiComplete) {
          record.taisCompleted += 1
          record[side].taisCompleted += 1
        }
        else {
          record.taisRemaining += 1
          record[side].taisRemaining += 1
        }

        // Percentages
        const sides = ['infatuation', 'resentment']
        sides.forEach((side) => {
          const columns = record[side].columns
          if (columns.total > 0) {
            if (columns.colsRemaining === 0)
              columns.percComplete = 100

            else if (columns.colsComplete === 0)
              columns.percComplete = 0

            else
              columns.percComplete = Math.round((columns.colsComplete / columns.total) * 100)
          }

          if (record[side].taisTotal > 0) {
            if (record[side].taisRemaining === 0)
              record[side].percComplete = 100

            else if (record[side].taisCompleted === 0)
              record[side].percComplete = 0

            else
              record[side].percComplete = Math.round((record[side].taisCompleted / record[side].taisTotal) * 100)
          }

          record[side].complete = record[side].percComplete >= 100
        })
      })

      // Global Columns Completion %
      if (record.columns.total > 0) {
        if (record.columns.colsRemaining === 0) {
          record.columns.percComplete = 100
          record.percComplete = 100
        }
        else if (record.columns.colsComplete === 0) {
          record.columns.percComplete = 0
          record.percComplete = 0
        }
        else {
          const result = Math.round((record.columns.colsComplete / record.columns.total) * 100)
          record.columns.percComplete = result
          record.percComplete = result
        }
      }
      else {
        record.columns.percComplete = 0
      }

      // Set session_staging_complete to true if all tais are complete
      if (record.taisRemaining === 0)
        record.sessionStagingComplete = true

      this.record = record
    },

    initialiseSession() {
      this.initialised = true
    },
    // initialisation =====================================================================

    DBupdateRecentSessionID(sessionID: string) {
      return new Promise<void>(async (resolve, reject) => {
        const $auth = useAuthStore()
        const { $api, $SE } = useNuxtApp()
        try {
          await $api.session.updateRecentSessionID(sessionID).then(() => {
            $auth.recentSession = Number.parseInt(sessionID)
            resolve()
          }).catch((error: any) => {
            throw error
          })
        }
        catch (error: any) {
          $SE(error, {
            extra: {
              info: `DBupdateRecentSessionID error - ${error.message}`,
              sessionID,
            },
          })
          reject(error)
        }
      })
    },
    // Use Zod to parse this so i can ensure the api is sending the correct payload && is correct
    initSession(session: SessionInitPayload) {
      const $panels = usePanelsStore()

      const sessionID = useCookie('session_id')
      // const recentSessionID = useCookie('rsession_id')

      this.session.type = session.type
      this.session.focus = session.focus
      this.session.individual.name = session.individual.name ?? ''
      this.session.individual.relation = session.individual.relation ?? ''

      $panels.initialisePanels(session.focus)

      // Models
      this.traits = Array.isArray(session.traits) ? session.traits : []

      // Columns (has to be added through a loop)
      session.columns.forEach((column) => {
        this.columns.push(column)
      })
      // Sides
      this.sides.traits = session.type
      if (session.activeColumn >= 9)
        this.sides.overview = 'resentment'

      this.setActive(session.activeTrait, session.activeColumn)

      this.values = Array.isArray(session.values) ? session.values : []

      // Set the session_id cookie
      sessionID.value = session.id
      // recentSessionID.value = session.id

      this.processRecord()

      this.initialised = true

      this.DBupdateRecentSessionID(session.id).catch((error: any) => {
        console.error('DBupdateRecentSessionID error', error.message)
      })
    },

    // Environment =====================================================================

    toggleSide(side?: SessionSide | undefined) {
      const route = useRoute()

      let sideEnv: 'traits' | 'overview'
      if (route.name === 'session-tais')
        sideEnv = 'traits'
      else if (route.name === 'session-overview')
        sideEnv = 'overview'
      else
        throw new Error('Session | Invalid active side request')

      if (side)
        this.sides[sideEnv] = side

      else this.sides[sideEnv] = this.sides[sideEnv] === 'infatuation' ? 'resentment' : 'infatuation'
    },

    // Trait Models Dependency Management  =====================================================================
    addTraitColumnsRecord(traitID: TraitID, side: SessionSide) {
      if (side === 'infatuation') {
        const payload: TraitColumnState = {
          tid: traitID,
          side: 'infatuation',
          cols: {
            2: { e: 0, t: 0, s: 'open' },
            3: { e: 0, t: 0, s: 'open' },
            4: { e: 0, t: 0, s: 'open' },
            5: { e: 0, t: 0, s: 'open' },
            6: { e: 0, t: 0, s: 'open' },
            7: { e: 0, t: 0, s: 'open' },
          },
        }
        this.columns.push(payload)
      }
      else if (side === 'resentment') {
        const payload: TraitColumnState = {
          tid: traitID,
          side: 'resentment',
          cols: {
            9: { e: 0, t: 0, s: 'open' },
            10: { e: 0, t: 0, s: 'open' },
            11: { e: 0, t: 0, s: 'open' },
            12: { e: 0, t: 0, s: 'open' },
            13: { e: 0, t: 0, s: 'open' },
            14: { e: 0, t: 0, s: 'open' },
          },
        }
        this.columns.push(payload)
      }
    },
    removeTraitColumnsRecord(traitID: TraitID) {
      this.columns = this.columns.filter(t => t.tid !== traitID)
    },

    // Traits =====================================================================
    // This function is to add a trait to the session from the UI

    updateColumnsWithNewIDs(columns, newColumnData) {
      const { tid, cols, side } = newColumnData
      const traitColumnState = columns.find(c => c.tid === tid && c.side === side)
      if (traitColumnState) {
        for (const colId in cols) {
          if (Object.prototype.hasOwnProperty.call(cols, colId)) {
            const column = traitColumnState.cols[colId]
            if (column)
              column.id = cols[colId].id
          }
        }
      }
    },
    DBAddTrait(trait: Trait) {
      return new Promise<void>(async (resolve, reject) => {
        const { $api, $SE } = useNuxtApp()

        try {
          // todo - Make sure i add this request to a queue, so if it fails, i can retry it.
          await $api.session.addTrait({
            label: trait.label,
            side: trait.side,
            tai_id: trait.id,
            tai_index: trait.index,
          }).then((TraitColumnsData) => {
            this.updateColumnsWithNewIDs(this.columns, TraitColumnsData)
            resolve()
          }).catch((error: any) => {
            // Todo: Add to queue on fail
            console.error('addTrait error', error.message)
            reject(error)
          })
        }
        catch (error: any) {
          // TODO Add to Queue if there are problems.
          $SE(error, {
            extra: {
              info: `DBAddTrait error - ${error.message}`,
              ...trait,
            },
          })
          reject(error)
        }
      })
    },
    DBUpdateTrait(traitID: TraitID, traitData: {
      label?: string
      tai_index?: number
      complete?: boolean
    }) {
      return new Promise<void>(async (resolve, reject) => {
        if (traitID && traitID > 0) {
          const { $api, $SE } = useNuxtApp()
          try {
            await $api.session.updateTrait({
              tai_id: traitID,
              ...traitData,
            }).then(() => {
              resolve()
            }).catch((error: any) => {
            // Todo: Add to queue on fail
              console.log('DBUpdateTrait error', error.message)
              reject(error)
            })
          }
          catch (error: any) {
          // TODO Add to Queue if there are problems.
            $SE(error, {
              extra: {
                message: `DBUpdateTrait error - ${error.message}`,
                ...traitData,
              },
            })
            reject(error)
          }
        }
      })
    },
    DBDeleteTrait(traitID: TraitID) {
      return new Promise<void>(async (resolve, reject) => {
        const { $api, $SE } = useNuxtApp()
        try {
          await $api.session.deleteTrait({
            tai_id: traitID,
          }).then(() => {
            resolve()
          }).catch((error: any) => {
            // Todo: Add to queue on fail
            console.log('DBDeleteTrait error', error.message)
            reject(error)
          })
        }
        catch (error: any) {
          // TODO Add to Queue if there are problems.
          console.log('DBDeleteTrait error', error.message)
          $SE(error, {
            extra: {
              info: `DBDeleteTrait error - ${error.message}`,
              traitID,
            },
          })
          reject(error)
        }
      })
    },
    addTrait(traitLabel: string) {
      const { toast } = useToast()

      const traitExists = this.traits.find(t => t.label === traitLabel)
      if (traitExists) {
        toast({
          type: 'warning',
          context: 'Add new trait',
          message: 'Trait already exists - please enter a new trait',
        })
      }
      else {
        let traitID = 1
        if (this.traits.length > 0) {
          const highestTid = Math.max(...this.traits.map(t => t.id))
          traitID = highestTid + 1
        }

        const trait: Trait = {
          id: traitID,
          index: this.getTraitsBySide(this.sides.traits).length,
          label: traitLabel,
          side: this.sides.traits,
        }
        this.traits.push(trait)

        // Toggle the side on the overview of the new trait
        this.sides.overview = this.sides.traits

        // Give the new trait a column entries totals record
        this.addTraitColumnsRecord(trait.id, this.sides.traits)

        this.DBAddTrait(trait)

        this.processRecord()
      }
    },
    updateTrait(id: TraitID, traitData: Partial<Trait> & { tai_index: number }, apiUpdate = true) {
      const trait = this.traits.find(t => t.id === id)
      if (trait) {
        if (Object.prototype.hasOwnProperty.call(traitData, 'tai_index')) {
          trait.index = traitData.tai_index
          const { tai_index, ...restData } = traitData
          Object.assign(trait, restData)
        }
        else {
          Object.assign(trait, traitData) // No tai_index, merge directly
        }
      }

      this.traits.sort((a, b) => a.index - b.index)

      if (apiUpdate)
        this.DBUpdateTrait(id, traitData)

      // this.DBUpdateTrait(id, traitData)
      // this.processRecord()
    },
    deleteTrait(traitID: number) {
      this.traits = this.traits.filter(t => t.id !== traitID)

      // Remove all trait model dependancies
      this.removeTraitColumnsRecord(traitID)
      this.processRecord()
      clearLocalStorageWithKeyContaining(`tai_${traitID}`)
      this.DBDeleteTrait(traitID)
    },

    // Session =====================================================================

    startSession() {
      return new Promise<void>(async (resolve, reject) => {
        try {
          const $app = useAppStore()
          const router = useRouter()

          $app.sessionState = 'session-in-progress'
          router.push(`/session/${this.column}`)

          resolve()
        }
        catch (error) {
          reject(error)
        }
      })
    },
    initColumn() {
      return new Promise<void>(async (resolve, reject) => {
        try {
          const { $api, $SE } = useNuxtApp()

          const columnState = this.columns.find(c => c.tid === this.trait.id)
          if (columnState && KIOE(columnState, ['tid', 'cols', 'side'])) {
            const columnID = columnState.cols[this.column].id
            if (columnID > 0) {
              await $api.session.initColumn(columnID).then((columnData: {
                entries: string[]
              }) => {
                this.initEntries(columnData.entries)
                resolve()
              }).catch((error: any) => {
                $SE(error, `$api.Session.initColumn error - ${error.message}`)
                reject(error)
              })
            }
            else {
              this.initEntries([])
              resolve()
            }
          }
        }
        catch (error: any) {
          reject(error)
        }
      })
    },

    resetActiveTrait() {
      this.trait = {
        id: -1,
        index: -1,
        label: '',
        side: 'infatuation',
      }
    },

    DBActiveTraitColumn() {
      return new Promise<void>(async (resolve, reject) => {
        try {
          const { $api, $SE } = useNuxtApp()
          await $api.session.updateSessionState({
            active_column: this.column,
            active_trait: this.trait.id,
          }).then(() => {
            // console.log('Active Columnstates updated')
            resolve()
          }).catch((error: any) => {
            $SE(error, `$api.session.updateColumnActiveState error - ${error.message}`)
            reject(error)
          })
          resolve()
        }
        catch (error) {
          reject(error)
        }
      })
    },
    // Set active trait && set active column
    setActive(traitID: number, column: ColumnID) {
      const trait = this.traits.find(t => t.id === traitID)

      if (trait && column > 0) {
        this.trait = trait
        this.column = column

        this.DBActiveTraitColumn().catch((error: any) => {
          // Todo Add to queue
          console.error('DBActiveTraitColumn error', error.message)
        })
      }
      else { this.resetActiveTrait() }
    },

    // Column =====================================================================
    incrementEntriesTotal() {
      const columnState = this.columns.find(c => c.tid === this.trait.id)
      if (columnState) {
        if (KIO(columnState.cols[this.column], 'e'))
          columnState.cols[this.column].e += 1
      }
    },
    DBUpdateColumnState(req: {
      columnID: number
      state: WorkingColumnState
    }) {
      return new Promise<void>(async (resolve, reject) => {
        try {
          const { $api, $SE } = useNuxtApp()
          await $api.session.updateColumnState(req.columnID, req.state).then(() => {
            // console.log('DBUpdateColumnState state success')
            resolve()
          }).catch((error: any) => {
            console.log('Error updaing the column state', error.message)
            $SE(error, `DBUpdateColumnState error - ${error.message}`)
            reject(error)
          })
        }
        catch (error) {
          reject(error)
        }
      })
    },

    // //todo This function is deprecated
    DBUpdateColumnStateTaiColumn(traitID: TraitID, column: ColumnID, state: WorkingColumnState) {
      return new Promise<void>(async (resolve, reject) => {
        try {
          const { $api, $SE } = useNuxtApp()
          await $api.session.updateColumnStateTaiIDColumn(traitID, column, state).then(() => {
            // console.log('DBUpdateColumnStateTaiColumn state success')
            resolve()
          }).catch((error: any) => {
            $SE(error, `DBUpdateColumnStateTaiColumn error - ${error.message}`)
            reject(error)
          })
        }
        catch (error) {
          reject(error)
        }
      })
    },

    updateColumnState(req: {
      column: ColumnID
      trait: TraitID
      state: WorkingColumnState
    }) {
      const trait = req.trait
      const column = req.column

      const columnState = this.columns.find(c => c.tid === trait)
      if (columnState && KIOE(columnState, ['tid', 'cols', 'side'])) {
        columnState.cols[column].s = req.state
        this.processRecord()
        this.DBUpdateColumnState({
          columnID: columnState.cols[column].id,
          state: req.state,
        }).catch((error: any) => {
          // Todo Add to queue
          console.error(`updateColumnState - DBUpdateColumnState - ${req.state} - error`, error.message)
        })
      }
      else {
        console.error(`updateColumnState - DBUpdateColumnState - ${req.state} - error`, error.message)
      }
    },

    completeColumn(columnID?: ColumnID, traitID?: TraitID) {
      const trait = traitID || this.trait.id
      const column = columnID || this.column
      this.updateColumnState({
        column,
        trait,
        state: 'complete',
      })
    },
    holdColumn(columnID?: ColumnID, traitID?: TraitID) {
      const trait = traitID || this.trait.id
      const column = columnID || this.column

      this.updateColumnState({
        column,
        trait,
        state: 'hold',
      })
    },
    openColumn(columnID?: ColumnID, traitID?: TraitID) {
      const trait = traitID || this.trait.id
      const column = columnID || this.column
      this.updateColumnState({
        column,
        trait,
        state: 'open',
      })
    },

    navigate(dir: 'left' | 'right' | 'up' | 'down') {
      const router = useRouter()
      switch (dir) {
        case 'left':
          if (this.canNavigate('left')) {
            this.column -= 1
            router.push(`/session/${this.column}`)
          }

          break
        case 'right':
          if (this.canNavigate('right')) {
            this.column += 1
            router.push(`/session/${this.column}`)
          }
          break
        case 'up':
          if (this.canNavigate('up')) {
            const traits = this.getTraitsBySide(this.sides.overview)
            const index = traits.findIndex(t => t.id === this.trait.id)
            if (index !== -1) {
              const nextTrait = traits.find(t => t.index === index - 1)
              if (nextTrait)
                this.setActive(nextTrait.id, this.column)
            }
          }
          break
        case 'down':
          if (this.canNavigate('down')) {
            const traits = this.getTraitsBySide(this.sides.overview)
            const index = traits.findIndex(t => t.id === this.trait.id)
            if (index !== -1) {
              const nextTrait = traits.find(t => t.index === index + 1)
              if (nextTrait)
                this.setActive(nextTrait.id, this.column)
            }
          }
          break

        default:
          break
      }
    },

    processScratch(entry: string) {
      let scratch = ''

      entry.trim()
      if (hasSpaces(entry)) {
        const arr = entry.split(' ')
        if (AEL(arr)) {
        // word + " " - keep the word until two clear words available
          if (arr.length === 2) {
            if (arr[1].length === 0) {
              return entry
            }
            else {
              for (let i = 0; i < arr.length; i++)
                scratch += strFirstLetters(arr[i])

              return scratch
            }
          }
          else {
            for (let i = 0; i < arr.length; i++)
              scratch += strFirstLetters(arr[i])

            return scratch
          }
        }
        else {
          return ''
        }
      }
      else {
        return entry
      }
    },
    initValues(values: string[]) {
      if (AEL(values))
        this.values = values

      else
        this.values = []
    },
    initEntries(entries: string[]) {
      if (AEL(entries)) {
        this.entries = entries

        this.scratches = ''
        for (let i = 0; i < entries.length; i++) {
          const entry = entries[i]
          this.scratches += this.processScratch(entry)
        }
      }
      else {
        this.entries = []
      }
    },

    // Column Entries
    commitEntry(entry: string) {
      if (entry) {
        this.entries.push(entry)

        this.incrementEntriesTotal()

        this.scratches += this.processScratch(entry)
        this.scratch = ''
      }
    },
    updateWorkingScratch(value: string) {
      this.scratch = this.processScratch(value)
    },

    // Column time ====================================================================

    DBSyncTime(columnID: number, time: number) {
      return new Promise<void>(async (resolve, reject) => {
        try {
          const { $api, $SE } = useNuxtApp()
          await $api.session.syncTime(columnID, time).then(() => {
            resolve()
          }).catch((error: any) => {
            $SE(error, `$api.session.syncTime error - ${error.message}`)
            reject(error)
          })
        }
        catch (error) {
          reject(error)
        }
      })
    },
    syncTime(request: {
      column: ColumnID
      columnID: number
      totalTime: number
    }) {
      const traitColumnState = this.columns.find(c => c.tid === this.trait.id)
      if (traitColumnState) {
        const col = traitColumnState.cols[request.column]

        if (request.totalTime > 0 && KIO(col, ['t', 'id'])) {
          col.t = request.totalTime
          this.DBSyncTime(col.id, col.t).catch((error: any) => {
            console.error('DBSyncTime error', error.message)
          })
        }
      }
    },

    // Session Consideration

    unlockColumn(trait: TraitID, column: ColumnID) {
      const $app = useAppStore()
      const traitColumnState = this.columns.find(c => c.tid === trait)
      if (traitColumnState) {
        traitColumnState.cols[column].s = 'open'
        this.processRecord()

        this.updateColumnState({
          column,
          trait,
          state: 'open',
        })

        if ($app.sessionState === 'session-complete-consideration')
          $app.sessionState = 'session-in-progress'
      }
    },

    async unlockColumnsForTrait(trait: TraitID): Promise<void> {
      const traitColumnState = this.columns.find(c => c.tid === trait)
      const promises: Promise<void>[] = []

      let columnOpened = false

      if (traitColumnState) {
        for (const column in traitColumnState.cols) {
          if (Object.prototype.hasOwnProperty.call(traitColumnState.cols, column)) {
            const col = traitColumnState.cols[column]
            if (col.s !== 'open') {
              col.s = 'open'
              columnOpened = true
              promises.push(this.updateColumnState({
                column,
                trait,
                state: 'open',
              }))
            }
          }
        }
        if (columnOpened) {
          if ($app.sessionState === 'session-complete-consideration')
            $app.sessionState = 'session-in-progress'
        }

        try {
          await Promise.allSettled(promises)
          this.processRecord()
        }
        catch (error: any) {
          // Handle errors if needed
          console.error('unlockColumnsForTrait error', error.message)
        }
      }
    },

    completeSession() {
      return new Promise<void>(async (resolve, reject) => {
        try {
          const { $api, $SE } = useNuxtApp()
          const $app = useAppStore()
          const router = useRouter()
          const $modal = useModalStore()

          const $userrecord = useUserRecordStore()

          $app.sessionState = 'session-complete'
          clearLocalStorageWithKeyContaining(`s_${this.session.id}_tai`)

          await $api.session.completeSession().then((record: UserRecord) => {
            $userrecord.initUserRecord(record).then(() => {
              setTimeout(() => {
                $modal.close()
                router.push('/session/complete')
                resolve()
              }, 300)
            }).catch((error: any) => {
              reject(error)
            })
          }).catch((error: any) => {
            $SE(error, `$api.Session.completeSession error - ${error.message}`)
            reject(error)
          })
        }
        catch (error) {
          reject(error)
        }
      })
    },

    deleteSession(sessionID: SessionID) {
      return new Promise<void>(async (resolve, reject) => {
        const { $api, $SE } = useNuxtApp()
        const $modal = useModalStore()
        const $userrecord = useUserRecordStore()
        if (sessionID) {
          await $api.session.deleteSession(sessionID).then((record: UserRecord) => {
            $userrecord.initUserRecord(record).then(() => {
              setTimeout(() => {
                $modal.close()
                resolve()
              }, 300)
            }).catch((error: any) => {
              $SE(error, `$api.session.deleteSession error - ${error.message}`)
              reject(error)
            })
          })
        }
        else {
          reject(new Error('Session | Delete Session | Session ID is not valid'))
        }
      })
    },

  },

  getters: {
    SI: () => {
      const $app = useAppStore()
      return $app.initialised
    },
    getInitialised: state => state.initialised,

    // Session =====================================================================
    // getActiveSide: (state) => {
    //   const route = useRoute()
    //   console.log('getActiveSide route.name', route.name)
    //   if (route.name === 'session-tais')
    //     return state.sides.traits
    //   else if (route.name === 'session-overview')
    //     return state.sides.overview
    //   else if (route.name === 'session-columnID')
    //     return state.sides.overview
    //   else
    //     throw new Error('Session | Invalid active side request')
    // },
    isFocusOther: state => state.session.focus === 'other',

    getIndividual: state => state.session.individual,
    getIndividualName: state => state.session.individual.name,
    getIndividualRelation: state => state.session.individual.relation,

    // Traits =====================================================================

    getTraits: state => state.traits,
    getTraitByID: state => (id: TraitID) => state.traits.find(t => t.id === id),
    getTraitsBySide: state => (side: SessionSide) => state.traits.filter(t => t.side === side).sort((a, b) => a.index - b.index),

    getTraitsSideAvailable: state => (side: SessionSide) => {
      if (side === state.session.type) {
        return true
      }
      else {
        const otherSide = side === 'infatuation' ? 'resentment' : 'infatuation'
        return state.traits.filter(t => t.side === otherSide).length > 0
      }
    },

    getTraitsTotal: state => state.traits.length,
    getTraitsTotalBySide: state => (side: SessionSide) => state.traits.filter(t => t.side === side).length,

    // Column state
    getTraitColumnsByID: state => (id: TraitID) => state.columns.find(c => c.tid === id),
    getColumnState: state => (traitID: number): SideAColumnState | SideBColumnState => {
      const column = state.columns.find(c => c.tid === traitID)

      if (column) {
        return column.cols
      }
      else {
        const route = useRoute()
        let side: SessionSide
        if (route.name === 'session-tais')
          side = state.sides.traits
        else if (route.name === 'session-overview')
          side = state.sides.overview
        else if (route.name === 'session-columnID')
          side = state.sides.overview
        else
          throw new Error('Session | Invalid active side request')

        if (side === 'infatuation') {
          return {
            ...blankColumnRecordA,
          }
        }
        else if (side === 'resentment') {
          return {
            ...blankColumnRecordB,
          }
        }
        else {
          throw new Error('Session | Invalid active side request')
        }
      }
    },

    // Stats =====================================================================

    getSideACompletionPerc: state => state.record.infatuation.columns.percComplete,
    getTraitsCompleteBySide: state => (side: SessionSide) => {
      const columnsStates = state.columns.filter(c => state.columns.find(t => t.side === side))
    },

    getTraitCompletionPerc: state => (id: TraitID) => {
      const column = state.columns.find(c => c.tid === id)
      if (column) {
        const columns = Object.values(column.cols)
        const colsComplete = columns.filter(c => c.s === 'complete').length

        if (colsComplete > 0) {
          const result = Math.round((colsComplete / columns.length) * 100)
          return result
        }
        else {
          return 0
        }
      }
      else {
        return 0
      }
    },

    // Navigation
    canNavigate: state => (dir: 'left' | 'right' | 'up' | 'down') => {
      const side = state.sides.overview
      const column = side === 'infatuation' ? state.column : state.column - 7
      const traits = state.traits.filter(t => t.side === state.sides.overview)

      switch (dir) {
        case 'left':
          if (column === 2)
            return false

          return true
        case 'right':

          if (column === 7)
            return false
          return true
        case 'up':
          if (state.trait.index === 0)
            return false

          return true
        case 'down':
          if (traits && (state.trait.index === traits.length - 1))
            return false

          return true

        default:
          break
      }
    },

    test: state => (id: number) => {
      return `test${id}`
    },

    traitComplete: state => (id?: TraitID | undefined) => {
      const traitID = id || state.trait.id
      const column = state.columns.find(c => c.tid === traitID)
      if (column) {
        const columns = Object.values(column.cols)
        return columns.every(colState => colState.s === 'complete')
      }
    },

    traitAbove: (state) => {
      const traits = state.traits.filter(t => t.side === state.sides.overview)
      const index = traits.findIndex(t => t.id === state.trait.id)
      if (index !== -1)
        return traits[index - 1]
      else
        return false
    },

    traitBelow(state) {
      const traits = state.traits.filter(t => t.side === state.sides.overview)
      const index = traits.findIndex(t => t.id === state.trait.id)
      if (index !== -1)
        return traits[index + 1]
      else
        return false
    },

    canTraitUp(state) {
      // Is there a trait above?
      if (state.trait.index === 0)
        return false

      const traitAbove = this.traitAbove || false

      if (!traitAbove)
        return false

      if (this.traitComplete(traitAbove.id))
        return false

      return true
    },
    canTraitDown(state) {
      const traits = state.traits.filter(t => t.side === state.sides.overview)

      // This is the last item in the arry
      if (traits.length > 0 && traits[traits.length - 1] && traits[traits.length - 1].index === state.trait.index)
        return false

      // Is the trait below complete?
      const traitBelow = this.traitBelow || false

      if (!traitBelow)
        return false

      if (this.traitComplete(traitBelow.id))
        return false

      return true
    },

    nextColumn(state) {
      const columns = state.columns.find(c => c.tid === state.trait.id)

      if (!columns)
        return false

      const colStates = columns.cols

      const columnID = state.sides.overview === 'resentment' ? state.column + 7 : state.column

      // Are we on the Last Column?
      if (columnID === 7) { // YES
        // Look for the next free column to the left

        for (const column in colStates) {
          const columnID = Number.parseInt(column) as keyof (SideAColumnState | SideBColumnState)
          if (Object.prototype.hasOwnProperty.call(colStates, column)) {
            const colState = colStates[columnID]
            if (colState.s === 'open') {
              return columnID
              break
            }
          }
        }

        // If there are no free columns to the left, look for the next hold column to the left
        for (const column in colStates) {
          const columnID = Number.parseInt(column) as keyof (SideAColumnState | SideBColumnState)
          if (Object.prototype.hasOwnProperty.call(colStates, column)) {
            const colState = colStates[columnID]
            if (colState.s === 'hold') {
              return columnID
              break
            }
          }
        }

        return 0
      }
      else { // NO
        // Look for the next free column to the right
        for (const column in colStates) {
          const columnID = Number.parseInt(column) as keyof (SideAColumnState | SideBColumnState)

          if (columnID <= state.column)
            continue

          if (Object.prototype.hasOwnProperty.call(colStates, column)) {
            const colState = colStates[columnID]
            if (colState.s === 'open') {
              return columnID
              break
            }
          }
        }

        // Look for the next free column to the left
        for (const column in colStates) {
          const columnID = Number.parseInt(column) as keyof (SideAColumnState | SideBColumnState)

          if (columnID >= state.column)
            continue

          if (Object.prototype.hasOwnProperty.call(colStates, column)) {
            const colState = colStates[columnID]
            if (colState.s === 'hold') {
              return columnID
              break
            }
          }
        }

        // Look for the next Hold column
        for (const column in colStates) {
          const columnID = Number.parseInt(column) as keyof (SideAColumnState | SideBColumnState)

          if (Object.prototype.hasOwnProperty.call(colStates, column)) {
            const colState = colStates[columnID]
            if (colState.s === 'hold') {
              return columnID
              break
            }
          }
        }

        return 0
      }

      // 			Is there a "free" column to the left
      // 				YES
      // 					Suggest Free column to the left
      // 				NO
      // 					Suggest Hold column to the left

      // 	NO
      // 		Is the next column complete or on hold?
      // 			YES
      // 				Is there another free Column to the right?
      // 					YES
      // 						Suggest the next free column to the right
      // 					NO
      // 						Is there another free column to the Left?
      // 							YES
      // 								Suggest free column to the left
      // 							NO
      // 								If there a Hold colunm to the left?
      // 									Yes
      // 										Suggest hold column to the left
      // 									No
      // 										Suggest Hold Column to the right

      // 			NO
      // 				Suggest Column + 1
    },
  },
})
