const panels = {
  infatuation: {
    self: {
      1: {
        id: 1,
        side: 'A',
        focus: 'self',
        type: 'infatuation',
        instruction:
          'Write (in 3-4 words for each) the top three highest priority specific traits, actions, or inactions ( AIs) that you perceive yourself to have displayed or demonstrated that you like or admire most (content). Make sure they are not derived from hearsay from others and that you can truly pinpoint exactly where and when you perceived them occurring and in what context. Be sure to exclude: synthesized transcendental feelings like loving or inspired; serial synonyms, like nice or kind; vague generalities or labels like good person or father; and how you felt as a result of you perceiving yourself displaying or demonstrating the specific TAIs) – like proud or smart.',
        instruction2: [
          'Write (in 3-4 words for each) the top three highest priority specific traits, actions, or inactions ( AIs) that you perceive yourself to have displayed or demonstrated that you like or admire most (content). Make sure they are not derived from hearsay from others and that you can truly pinpoint exactly where and when you perceived them occurring and in what context.',
          'Be sure to exclude: synthesized transcendental feelings like loving or inspired; serial synonyms, like nice or kind; vague generalities or labels like good person or father; and how you felt as a result of you perceiving yourself displaying or demonstrating the specific TAIs) – like proud or smart.',
        ],
        instruction3: [
          'Write (in 3-4 words for each) the top three highest priority specific traits, actions, or inactions ( AIs) that you perceive yourself to have displayed or demonstrated that you like or admire most (content).',
          'Make sure they are not derived from hearsay from others and that you can truly pinpoint exactly where and when you perceived them occurring and in what context.',
          'Be sure to exclude: synthesized transcendental feelings like loving or inspired; serial synonyms, like nice or kind; vague generalities or labels like good person or father; and how you felt as a result of you perceiving yourself displaying or demonstrating the specific TAIs) – like proud or smart.',
        ],
        example: 'Financially generous to others',
        goal: '3',
        objective: 'Identifies Most Admired (positive) Emotional Charges',
        hint: 'e.g. Considerate to me',
        confirmation:
          'Are you now <strong>certain</strong> that you have listed the top three highest priority specific traits, actions, or inactions (TAIs) that you perceived yourself displaying or demonstrating that you most like, admire, look up to, infatuate, or consider positive or attractive?',
      },
      2: {
        id: 2,
        side: 'A',
        focus: 'self',
        type: 'infatuation',
        instruction:
          'Go to a specific moment, where and when you perceived yourself displaying or demonstrating this specific trait, action, or inaction (TAI) that you liked or admired in yourself most (listed in column 1). Then, write in an abbreviated & overlapping format <strong>where</strong>, <strong>when</strong> and to <strong>whom</strong> you perceive yourself displaying or demonstrating this (TAI) to and who perceived you displaying or demonstrating it. Consider all 7 areas of your life from past to present. Those who perceived you displaying or demonstrating this specific TAI could be, you, the individual you displayed or demonstrated it to or another perceiver. ',
        instruction2: [
          'Go to a specific moment, where and when you perceived yourself displaying or demonstrating this specific trait, action, or inaction (TAI) that you liked or admired in yourself most (listed in column 1). Then, write in an abbreviated & overlapping format <strong>where</strong>, <strong>when</strong> and to <strong>whom</strong> you perceive yourself displaying or demonstrating this (TAI) to',
          'and who perceived you displaying or demonstrating it. Consider all 7 areas of your life from past to present. Those who perceived you displaying or demonstrating this specific TAI could be, you, the individual you displayed or demonstrated it to or another perceiver.',
        ],
        instruction3: [
          'Go to a specific moment, where and when you perceived yourself displaying or demonstrating this specific trait, action, or inaction (TAI) that you liked or admired in yourself most (listed in column 1).',
          'Then, write in an abbreviated & overlapping format <strong>where</strong>, <strong>when</strong> and to <strong>whom</strong> you perceive yourself displaying or demonstrating this (TAI) to and who perceived you displaying or demonstrating it.',
          'Consider all 7 areas of your life from past to present. Those who perceived you displaying or demonstrating this specific TAI could be, you, the individual you displayed or demonstrated it to or another perceiver.',
        ],
        goal: '20-50',
        objective: 'Confirms Laws of Reflection & Transparency',
        hint:
          'wwww',
        confirmation:
          'Are you now <strong>certain</strong> that you perceive yourself displaying or demonstrating this specific trait, action, or inaction (TAI) that you most liked or admired in yourself – the where, the when, and to whom? Are you now certain that you also have identified who perceived you displaying or demonstrating this TAI?',
      },
      3: {
        id: 3,
        side: 'A',
        focus: 'self',
        type: 'infatuation',
        instruction:
          'Go to the specific moment where and when you perceived yourself displaying or demonstrating this specific trait, action, or inaction that you liked or admired in yourself most. Then write in an abbreviated & overlapping format how this specific trait, action, or inaction, in that moment and from that moment until today was a drawback or disservice to you in your three highest values and remaining seven areas of life. Include primary, secondary & tertiary drawbacks. Exclude any self-minimizing answers. Be sure it is the drawbacks or disservices to you of you displaying or demonstrating the specific TAI and not the drawbacks or disservices to you of you doing the opposite or not displaying or demonstrating the specific TAI.',
        instruction2: [
          'Go to the specific moment where and when you perceived yourself displaying or demonstrating this specific trait, action, or inaction that you liked or admired in yourself most. Then write in an abbreviated & overlapping format how this specific trait, action, or inaction, in that moment and from that moment until today was a drawback or disservice to you in your three highest values and remaining seven areas of life.',
          'Include primary, secondary & tertiary drawbacks. Exclude any self-minimizing answers. Be sure it is the drawbacks or disservices to you of you displaying or demonstrating the specific TAI and not the drawbacks or disservices to you of you doing the opposite or not displaying or demonstrating the specific TAI.',
        ],
        instruction3: [
          'Go to the specific moment where and when you perceived yourself displaying or demonstrating this specific trait, action, or inaction that you liked or admired in yourself most.',
          'Then write in an abbreviated & overlapping format how this specific trait, action, or inaction, in that moment and from that moment until today was a drawback or disservice to you in your three highest values and remaining seven areas of life.',
          'Include primary, secondary & tertiary drawbacks. Exclude any self-minimizing answers. Be sure it is the drawbacks or disservices to you of you displaying or demonstrating the specific TAI and not the drawbacks or disservices to you of you doing the opposite or not displaying or demonstrating the specific TAI.',
        ],
        goal: '20-50',
        objective: 'Dissolves Other-Infatuation & Self-minimization',
        hint:
          'TR DF SB MF ES CB IJ AY IH GT JF',
        confirmation:
          'Are you now <strong>certain</strong> that the specific trait, action, or inaction (TAI) that you perceived yourself displaying or demonstrating, that you liked and admired most listed in Column 1, was also just as equally a drawback or disservice to you as it was a benefit or service? Is it now neither positive nor negative?',
      },
      4: {
        id: 4,
        side: 'A',
        focus: 'self',
        type: 'infatuation',
        instruction:
          'Go to the specific moment where and when you perceived yourself displaying or demonstrating this specific trait, action, or inaction (TAI) that you liked or admired in yourself most. Then write in an abbreviated & overlapping format how this specific trait, action, or inaction that you perceived yourself displaying or demonstrating was in that moment and until today a drawback or disservice to those you perceive yourself displaying or demonstrating this trait, action, or inaction to – or to those perceiving you. Exclude any other–minimizing answers. Include primary, secondary & tertiary drawbacks or disservices. Check in every three answers to assure that you do not go too far and transform your pride into shame. Apply this Column 4 to each moment listed in Column 2 until each moment becomes neither positive nor negative.',
        instruction2: [
          'Go to the specific moment where and when you perceived yourself displaying or demonstrating this specific trait, action, or inaction (TAI) that you liked or admired in yourself most. Then write in an abbreviated & overlapping format how this specific trait, action, or inaction that you perceived yourself displaying or demonstrating was in that moment and until today a drawback or disservice to those you perceive yourself displaying or demonstrating this trait, action, or inaction to – or to those perceiving you.',
          'Exclude any other–minimizing answers. Include primary, secondary & tertiary drawbacks or disservices. Check in every three answers to assure that you do not go too far and transform your pride into shame. Apply this Column 4 to each moment listed in Column 2 until each moment becomes neither positive nor negative.',
        ],
        instruction3: [
          'Go to the specific moment where and when you perceived yourself displaying or demonstrating this specific trait, action, or inaction (TAI) that you liked or admired in yourself most.',
          'Then write in an abbreviated & overlapping format how this specific trait, action, or inaction that you perceived yourself displaying or demonstrating was in that moment and until today a drawback or disservice to those you perceive yourself displaying or demonstrating this trait, action, or inaction to – or to those perceiving you.',
          'Exclude any other–minimizing answers. Include primary, secondary & tertiary drawbacks or disservices. Check in every three answers to assure that you do not go too far and transform your pride into shame. Apply this Column 4 to each moment listed in Column 2 until each moment becomes neither positive nor negative.',
        ],
        goal: '20-70',
        objective: 'Dissolves Self-Exaggeration & Pride',
        hint:
          'TR DF SB MF ES CB IJ AY IH GT JF',
        confirmation:
          'Are you now <strong>certain</strong> that the specific trait, action, or inaction (TAI) in yourself that you listed in Column 1 was also just as equally a drawback or disservice to those specific others you have perceived yourself displaying or demonstrated it to, or any perceivers, as it was a benefit or service to them? Are each now neither positive nor negative?',
      },
      5: {
        id: 5,
        side: 'A',
        focus: 'self',
        type: 'infatuation',
        instruction:
          'Go to the specific moment where and when you perceived yourself displaying or demonstrating this specific trait, action, or inaction (TAI) that you liked or admired in yourself most. Who did you perceive yourself displaying or demonstrating the specific TAI to (vector)? Then write in an abbreviated & overlapping format where and when you perceived yourself at any time displaying or demonstrating the exact opposite specific trait, action, or inaction to the same individual or group you displayed or demonstrated the original specific trait, action, or inaction to and who perceived it. Keep writing abbreviations until you are certain that the exact opposite specific TAI equals the original specific TAI quantitatively and qualitatively. Do this for each individual that you perceived yourself displaying or demonstrating this specific TAI to.',
        instruction2: [
          'Go to the specific moment where and when you perceived yourself displaying or demonstrating this specific trait, action, or inaction (TAI) that you liked or admired in yourself most. Who did you perceive yourself displaying or demonstrating the specific TAI to (vector)? Then write in an abbreviated & overlapping format where and when you perceived yourself at any time displaying or demonstrating the exact opposite specific trait, action, or inaction to the same individual',
          'or group you displayed or demonstrated the original specific trait, action, or inaction to and who perceived it. Keep writing abbreviations until you are certain that the exact opposite specific TAI equals the original specific TAI quantitatively and qualitatively. Do this for each individual that you perceived yourself displaying or demonstrating this specific TAI to.',
        ],
        instruction3: [
          'Go to the specific moment where and when you perceived yourself displaying or demonstrating this specific trait, action, or inaction (TAI) that you liked or admired in yourself most.',
          'Who did you perceive yourself displaying or demonstrating the specific TAI to (vector)? Then write in an abbreviated & overlapping format where and when you perceived yourself at any time displaying or demonstrating the exact opposite specific trait, action, or inaction to the same individual or group you displayed or demonstrated the original specific trait, action, or inaction to and who perceived it.',
          'Keep writing abbreviations until you are certain that the exact opposite specific TAI equals the original specific TAI quantitatively and qualitatively. Do this for each individual that you perceived yourself displaying or demonstrating this specific TAI to.',
        ],
        goal: '20-50',
        objective:
          'Dissolves Exaggerated Labels & Enhances Communication & Immunity',
        hint:
          'wwww',
        confirmation:
          'Are you now <strong>certain</strong> that you perceived yourself displaying or demonstrating the specific trait, action, or inaction and the exact opposite specific trait, action, or inaction (ant-TAI) equally to the same individuals (yourself, others, or group) to the same degree quantitatively and qualitatively? Are you now certain there are no more subjective “Alls” or “Nones” or “Always” or “Nevers”?',
      },
      6: {
        id: 6,
        side: 'A',
        focus: 'self',
        type: 'infatuation',
        instruction:
          'Go to the specific moment where and when you perceived yourself displaying or demonstrating this specific trait, action, or inaction (TAI) that you liked or admired in yourself most. Where are you? When are you? Get present in the specific moment. Who are you displaying or demonstrating the specific TAI to (vector)? What specifically is the content and context? Write in an abbreviated & overlapping format the initial(s) of those individuals who displayed or demonstrated the exact opposite specific trait, action, or inaction to the same individual or group at the exact same synchronous moment. Make sure they are quantitatively and qualitatively equal and opposite. Exhaust all moments until each of the entangled opposites are synchronized. (One/Many, Close/Distant Virtual/Real) (18-36) Male/Female, Self/Other, Virtual/Real)',
        instruction2: [
          'Go to the specific moment where and when you perceived yourself displaying or demonstrating this specific trait, action, or inaction (TAI) that you liked or admired in yourself most. Where are you? When are you? Get present in the specific moment. Who are you displaying or demonstrating the specific TAI to (vector)? What specifically is the content and context? Write in an abbreviated & overlapping format the initial(s) of those individuals',
          'who displayed or demonstrated the exact opposite specific trait, action, or inaction to the same individual or group at the exact same synchronous moment. Make sure they are quantitatively and qualitatively equal and opposite. Exhaust all moments until each of the entangled opposites are synchronized. (One/Many, Close/Distant Virtual/Real) (18-36) Male/Female, Self/Other, Virtual/Real)',
        ],
        instruction3: [
          'Go to the specific moment where and when you perceived yourself displaying or demonstrating this specific trait, action, or inaction (TAI) that you liked or admired in yourself most. Where are you? When are you? Get present in the specific moment',
          'Who are you displaying or demonstrating the specific TAI to (vector)? What specifically is the content and context? Write in an abbreviated & overlapping format the initial(s) of those individuals who displayed or demonstrated the exact opposite specific trait, action, or inaction to the same individual or group at the exact same synchronous moment',
          'Make sure they are quantitatively and qualitatively equal and opposite. Exhaust all moments until each of the entangled opposites are synchronized. (One/Many, Close/Distant Virtual/Real) (18-36) Male/Female, Self/Other, Virtual/Real)',
        ],
        goal: '18-36',
        objective: 'Demonstrates Synchronicity & Hidden Intelligent Order',
        hint:
          'WWWCCP',
        confirmation:
          'Are you now <strong>certain</strong> that either you or some other individual(s) were simultaneously displaying or demonstrating the exact opposite specific trait, action, or inaction to what you perceived yourself displaying or demonstrating to the specific other individual in order to synchronously balance out that specific character trait, action, or inaction you listed in Column 1 quantitatively & qualitatively?',
      },
      7: {
        id: 7,
        side: 'A',
        focus: 'self',
        type: 'infatuation',
        instruction:
          'Go to the specific moment where and when you perceived yourself displaying or demonstrating this specific trait, action, or inaction (TAI) that you liked or admired in yourself most. Then write in an abbreviated & overlapping format the benefits to you and to the specific individual or group that you perceived yourself displaying or demonstrating this specific trait, action, or inaction to - if at that exact same moment you were to have perceived yourself displaying or demonstrating the exact opposite specific trait, action, or inaction to those listed in Column 1, i.e. the way you had wished or hoped you had not done. Include primary, secondary & tertiary benefits or services. Do this for each moment you have perceived yourself displaying or demonstrating this specific TAI.',
        instruction2: [
          'Go to the specific moment where and when you perceived yourself displaying or demonstrating this specific trait, action, or inaction (TAI) that you liked or admired in yourself most. Then write in an abbreviated & overlapping format the benefits to you and to the specific individual or group that you perceived yourself displaying or demonstrating this specific trait, action, or inaction to - if at that exact same moment you were to have perceived yourself',
          'displaying or demonstrating the exact opposite specific trait, action, or inaction to those listed in Column 1, i.e. the way you had wished or hoped you had not done. Include primary, secondary & tertiary benefits or services. Do this for each moment you have perceived yourself displaying or demonstrating this specific TAI.',
        ],
        instruction3: [
          'Go to the specific moment where and when you perceived yourself displaying or demonstrating this specific trait, action, or inaction (TAI) that you liked or admired in yourself most.',
          'Then write in an abbreviated & overlapping format the benefits to you and to the specific individual or group that you perceived yourself displaying or demonstrating this specific trait, action, or inaction to - if at that exact same moment you were to have perceived yourself displaying or demonstrating the exact opposite specific trait, action, or inaction to those listed in Column 1, i.e. the way you had wished or hoped you had not done.',
          'Include primary, secondary & tertiary benefits or services. Do this for each moment you have perceived yourself displaying or demonstrating this specific TAI.',
        ],
        goal: '20-50',
        objective:
          'Dissolves Nightmares & The Driving Forces of Fantasies / Philias',
        hint:
          'wwww',
        confirmation:
          'Are you now <strong>certain</strong> that if you were to have perceived yourself displaying or demonstrating the exact opposite specific trait, action, or inaction (or anti-TAI) to that listed in Column 1 at that same moment, there would have been just as many benefits, positives, or services as drawbacks, negatives, or dis-services quantitatively & qualitatively?',
      },
    },
    other: {
      1: {
        id: 1,
        side: 'A',
        focus: 'other',
        type: 'infatuation',
        instruction:
          'Write (in 3-4 words for each) the top three highest priority specific traits, actions, or inactions ( AIs) that you perceive this individual above has displayed or demonstrated that you like or admire most (content). Make sure they are not derived from hearsay from others and that you can truly pinpoint exactly where and when you perceived them occurring and in what context. Be sure to exclude: synthesized transcendental feelings like loving or inspired; serial synonyms, like nice or kind; vague generalities or labels like good person, saintly, or good father; and how you felt as a result of you perceiving this individual displaying or demonstrating the specific TAIs) – like proud or smart.',
        instruction2: [
          'Write (in 3-4 words for each) the top three highest priority specific traits, actions, or inactions ( AIs) that you perceive this individual above has displayed or demonstrated that you like or admire most (content). Make sure they are not derived from hearsay from others and that you can truly pinpoint exactly where and when you perceived them occurring and in what context.',
          'Be sure to exclude: synthesized transcendental feelings like loving or inspired; serial synonyms, like nice or kind; vague generalities or labels like good person, saintly, or good father; and how you felt as a result of you perceiving this individual displaying or demonstrating the specific TAIs) – like proud or smart.',
        ],
        instruction3: [
          'Write (in 3-4 words for each) the top three highest priority specific traits, actions, or inactions ( AIs) that you perceive this individual above has displayed or demonstrated that you like or admire most (content).',
          'Make sure they are not derived from hearsay from others and that you can truly pinpoint exactly where and when you perceived them occurring and in what context.',
          'Be sure to exclude: synthesized transcendental feelings like loving or inspired; serial synonyms, like nice or kind; vague generalities or labels like good person, saintly, or good father; and how you felt as a result of you perceiving this individual displaying or demonstrating the specific TAIs) – like proud or smart.',
        ],
        goal: '3',
        example: 'Financially generous to me',
        objective: 'Identifies Positive Emotional Charge',
        hint: 'Ex: Considerate to me',
        confirmation:
          'Are you now <strong>certain</strong> that you have listed the top three highest priority specific traits, actions, or inactions (TAIs) that you perceive this individual above has displayed or demonstrated that you most like, admire, look up to, infatuate with, or consider positive or attractive?',
      },
      2: {
        id: 2,
        side: 'A',
        focus: 'other',
        type: 'infatuation',
        instruction:
          'Go to a specific moment, where and when you perceived yourself displaying or demonstrating this same or similar specific trait, action, or inaction (TAI) that you liked or admired in this individual most (listed in column 1). Then, write in an abbreviated & overlapping format where, when and to whom you perceived yourself displaying or demonstrating this (TAI) to and who perceived you displaying or demonstrating it until you can state with certainty that you own this trait, action, or inaction 100% as much as you have perceived it in them, both quantitatively / qualitatively. Consider all 7 areas of your life from past to present. Those who perceived you displaying or demonstrating this specific TAI could be you, the individual you displayed or demonstrated it to, or another perceiver.',
        instruction2: [
          'Go to a specific moment, where and when you perceived yourself displaying or demonstrating this same or similar specific trait, action, or inaction (TAI) that you liked or admired in this individual most (listed in column 1).Then, write in an abbreviated & overlapping format where, when and to whom you perceived yourself displaying or demonstrating this (TAI) to',
          'and who perceived you displaying or demonstrating it until you can state with certainty that you own this trait, action, or inaction 100% as much as you have perceived it in them, both quantitatively / qualitatively. Consider all 7 areas of your life from past to present. Those who perceived you displaying or demonstrating this specific TAI could be you, the individual you displayed or demonstrated it to, or another perceiver.',
        ],
        instruction3: [
          'Go to a specific moment, where and when you perceived yourself displaying or demonstrating this same or similar specific trait, action, or inaction (TAI) that you liked or admired in this individual most (listed in column 1).',
          'Then, write in an abbreviated & overlapping format where, when and to whom you perceived yourself displaying or demonstrating this (TAI) to and who perceived you displaying or demonstrating it until you can state with certainty that you own this trait, action, or inaction 100% as much as you have perceived it in them, both quantitatively / qualitatively.',
          'Consider all 7 areas of your life from past to present. Those who perceived you displaying or demonstrating this specific TAI could be you, the individual you displayed or demonstrated it to, or another perceiver.',
        ],
        goal: '20-50',
        objective: 'Confirms Laws of Reflection & Transparency',
        hint:
          'wwww',
        confirmation:
          'Are you now <strong>certain</strong> that you perceive yourself displaying or demonstrating this same or similar specific trait, action, or inaction (TAI) that you most liked or admired in this individual 100% to the same degree quantitatively and qualitatively?',
      },
      3: {
        id: 3,
        side: 'A',
        focus: 'other',
        type: 'infatuation',
        instruction:
          'Go to the specific moment where and when you perceived this individual displaying or demonstrating this specific trait, action, or inaction that you liked or admired most. Then write in an abbreviated & overlapping format how this specific trait, action, or inaction, in that moment and from that moment until today was a drawback or disservice to you (or another individual and you) in your three highest values and remaining seven areas of life. Include primary, secondary & tertiary drawbacks. Exclude any self minimizing answers. Be sure it is the drawbacks or disservices to you of them displaying or demonstrating the specific TAI and not the drawbacks or disservices to you of you doing the opposite or not displaying or demonstrating the specific TAI.',
        instruction2: [
          'Go to the specific moment where and when you perceived this individual displaying or demonstrating this specific trait, action, or inaction that you liked or admired most. Then write in an abbreviated & overlapping format how this specific trait, action, or inaction, in that moment and from that moment until today was a drawback or disservice to you (or another individual and you) in your three highest values and remaining seven areas of life.',
          'Include primary, secondary & tertiary drawbacks. Exclude any self minimizing answers. Be sure it is the drawbacks or disservices to you of them displaying or demonstrating the specific TAI and not the drawbacks or disservices to you of you doing the opposite or not displaying or demonstrating the specific TAI.',
        ],
        instruction3: [
          'Go to the specific moment where and when you perceived this individual displaying or demonstrating this specific trait, action, or inaction that you liked or admired most.',
          'Then write in an abbreviated & overlapping format how this specific trait, action, or inaction, in that moment and from that moment until today was a drawback or disservice to you (or another individual and you) in your three highest values and remaining seven areas of life.',
          'Include primary, secondary & tertiary drawbacks. Exclude any self minimizing answers. Be sure it is the drawbacks or disservices to you of them displaying or demonstrating the specific TAI and not the drawbacks or disservices to you of you doing the opposite or not displaying or demonstrating the specific TAI.',
        ],
        goal: '20-50',
        objective: 'Dissolves Other-Infatuation & Self-minimization',
        hint:
          'TR DF SB MF ES CB IJ AY IH GT JF',
        confirmation:
          'Are you now <strong>certain</strong> that the specific trait, action, or inaction (TAI) that you perceived this individual displaying or demonstrating, that you liked and admired most listed in Column 1, was also just as equally a drawback or disservice to you (or another individual and you) as it was a benefit or service? Is it now neither positive nor negative?',
      },
      4: {
        id: 4,
        side: 'A',
        focus: 'other',
        type: 'infatuation',
        instruction:
          'Go to the specific moment where and when you perceived yourself displaying or demonstrating this same or similar specific trait, action, or inaction (TAI) that you liked or admired in this individual most. Then write in an abbreviated & overlapping format how this specific trait, action, or inaction that you perceived yourself displaying or demonstrating was in that moment and until today a drawback or disservice to those you perceived yourself displaying or demonstrating this trait, action, or inaction to – or to those perceiving you. Exclude any other–minimizing answers. Include primary, secondary & tertiary drawbacks or disservices. Check in every three answers to assure that you do not go too far and transform your pride into shame. Apply this Column 4 to each moment listed in Column 2 until each moment becomes neither positive nor negative.',
        instruction2: [
          'Go to the specific moment where and when you perceived yourself displaying or demonstrating this same or similar specific trait, action, or inaction (TAI) that you liked or admired in this individual most. Then write in an abbreviated & overlapping format how this specific trait, action, or inaction that you perceived yourself displaying or demonstrating was in that moment and until today a drawback or disservice to those you perceived yourself displaying or demonstrating this trait, action, or inaction to – or to those perceiving you',
          'Exclude any other–minimizing answers. Include primary, secondary & tertiary drawbacks or disservices. Check in every three answers to assure that you do not go too far and transform your pride into shame. Apply this Column 4 to each moment listed in Column 2 until each moment becomes neither positive nor negative.',
        ],
        instruction3: [
          'Go to the specific moment where and when you perceived yourself displaying or demonstrating this same or similar specific trait, action, or inaction (TAI) that you liked or admired in this individual most.',
          'Then write in an abbreviated & overlapping format how this specific trait, action, or inaction that you perceived yourself displaying or demonstrating was in that moment and until today a drawback or disservice to those you perceived yourself displaying or demonstrating this trait, action, or inaction to – or to those perceiving you.',
          'Exclude any other–minimizing answers. Include primary, secondary & tertiary drawbacks or disservices. Check in every three answers to assure that you do not go too far and transform your pride into shame. Apply this Column 4 to each moment listed in Column 2 until each moment becomes neither positive nor negative.',
        ],
        objective: 'Dissolves Self-Exaggeration & Pride',
        goal: '20-70',
        hint:
          'TR DF SB MF ES CB IJ AY IH GT JF',
        confirmation:
          'Are you now <strong>certain</strong> that the specific trait, action, or inaction (TAI) in this individual you listed in Column 1 and reflected and confirmed equally in yourself in Column 2 was also just as equally a drawback or disservice to those specific others you have perceived yourself demonstrated them to – or to those perceiving you - as it was a benefit or service to them? Are each now neither positive nor negative?',
      },
      5: {
        id: 5,
        side: 'A',
        focus: 'other',
        type: 'infatuation',
        instruction:
          'Go to the specific moment where and when you perceived this individual displaying or demonstrating this specific trait, action, or inaction (TAI) that you liked or admired most. Who did you perceive them displaying or demonstrating the specific TAI to (vector)? Then write in an abbreviated & overlapping format where and when you perceived this individual at any time displaying or demonstrating the exact opposite specific trait, action, or inaction to the same individual or group they displayed or demonstrated the original specific trait, action, or inaction to and who perceived it. Keep writing overlapping abbreviations until you are certain that the exact opposite specific TAI equals the original specific TAI quantitatively and qualitatively. Do this for each individual that you perceived them displaying or demonstrating this specific TAI to.',
        instruction2: [
          'Go to the specific moment where and when you perceived this individual displaying or demonstrating this specific trait, action, or inaction (TAI) that you liked or admired most. Who did you perceive them displaying or demonstrating the specific TAI to (vector)? Then write in an abbreviated & overlapping format where and when you perceived this individual at any time displaying or demonstrating the exact opposite specific trait, action, or inaction to the same individual or group',
          'they displayed or demonstrated the original specific trait, action, or inaction to and who perceived it. Keep writing overlapping abbreviations until you are certain that the exact opposite specific TAI equals the original specific TAI quantitatively and qualitatively. Do this for each individual that you perceived them displaying or demonstrating this specific TAI to.',
        ],
        instruction3: [
          'Go to the specific moment where and when you perceived this individual displaying or demonstrating this specific trait, action, or inaction (TAI) that you liked or admired most.',
          'Who did you perceive them displaying or demonstrating the specific TAI to (vector)? Then write in an abbreviated & overlapping format where and when you perceived this individual at any time displaying or demonstrating the exact opposite specific trait, action, or inaction to the same individual or group they displayed or demonstrated the original specific trait, action, or inaction to and who perceived it.',
          'Keep writing overlapping abbreviations until you are certain that the exact opposite specific TAI equals the original specific TAI quantitatively and qualitatively. Do this for each individual that you perceived them displaying or demonstrating this specific TAI to.',
        ],
        goal: '20-50',
        objective:
          'Dissolves Exaggerated Labels & Enhances Communication & Immunity',
        hint:
          'wwww',
        confirmation:
          'Are you now <strong>certain</strong> that this individual above has displayed or demonstrated the specific trait, action, or inaction and the exact opposite specific trait, action, or inaction (ant-TAI) equally to the same individuals (yourself, others or group) to the same degree quantitatively and qualitatively? Are you now certain there are no more subjective “Alls” or “Nones” or “Always” or “Nevers”?',
      },
      6: {
        id: 6,
        side: 'A',
        focus: 'other',
        type: 'infatuation',
        instruction:
          'Go to the specific moment where and when you perceived this individual displaying or demonstrating this specific trait, action, or inaction (TAI) that you liked or admired most. Where are you? When are you? Get present in the specific moment. Who is this individual displaying or demonstrating the specific TAI to (vector)? What specifically is the content and context? Write in an abbreviated & overlapping format the initial(s) of those individuals who displayed or demonstrated the exact opposite specific trait, action, or inaction to the same individual or group at the exact same synchronous moment. Make sure they are quantitatively and qualitatively equal and opposite. Exhaust all moments until each of the entangled opposites are synchronized. (One/Many, Male/Female, Close/Distant, Self/Other, Virtual/Real)',
        instruction2: [
          'Go to the specific moment where and when you perceived this individual displaying or demonstrating this specific trait, action, or inaction (TAI) that you liked or admired most. Where are you? When are you? Get present in the specific moment. Who is this individual displaying or demonstrating the specific TAI to (vector)? What specifically is the content and context? Write in an abbreviated & overlapping format the initial(s) of those individuals',
          'who displayed or demonstrated the exact opposite specific trait, action, or inaction to the same individual or group at the exact same synchronous moment. Make sure they are quantitatively and qualitatively equal and opposite. Exhaust all moments until each of the entangled opposites are synchronized. (One/Many, Male/Female, Close/Distant, Self/Other, Virtual/Real)',
        ],
        instruction3: [
          'Go to the specific moment where and when you perceived this individual displaying or demonstrating this specific trait, action, or inaction (TAI) that you liked or admired most',
          'Where are you? When are you? Get present in the specific moment. Who is this individual displaying or demonstrating the specific TAI to (vector)? What specifically is the content and context? Write in an abbreviated & overlapping format the initial(s) of those individuals who displayed or demonstrated the exact opposite specific trait, action, or inaction to the same individual or group at the exact same synchronous moment.',
          'Make sure they are quantitatively and qualitatively equal and opposite. Exhaust all moments until each of the entangled opposites are synchronized. (One/Many, Male/Female, Close/Distant, Self/Other, Virtual/Real)',
        ],
        goal: '18-36',
        objective: 'Demonstrates Synchronicity & Hidden Intelligent Order',
        hint:
          'WWWCCP',
        confirmation:
          'Are you now <strong>certain</strong> that either you or some other individual were simultaneously displaying or demonstrating the exact opposite specific trait, action, or inaction that you perceived this individual displaying or demonstrating to you or a specific other individual in order to synchronously balance out that specific character trait, action, or inaction you listed in Column 1 quantitatively & qualitatively?',
      },
      7: {
        id: 7,
        side: 'A',
        focus: 'other',
        type: 'infatuation',
        instruction:
          'Go to the specific moment where and when you perceived this individual displaying or demonstrating this specific trait, action, or inaction (TAI) that you liked or admired most. Then write in an abbreviated & overlapping format the benefits or services to you and to the specific other individual or group you perceived the individual was displaying or demonstrating the specific trait, action, or inaction to - if at that exact same moment this individual were to have been perceived displaying or demonstrating the exact opposite specific trait, action, or inaction to those listed in Column 1, i.e. the way you had wished or hoped they had not done. Include primary, secondary & tertiary benefits or services. Go to each moment you perceived this individual displaying or demonstrating this specific TAI. ',
        instruction2: [
          'Go to the specific moment where and when you perceived this individual displaying or demonstrating this specific trait, action, or inaction (TAI) that you liked or admired most. Then write in an abbreviated & overlapping format the benefits or services to you and to the specific other individual or group you perceived the individual was displaying or demonstrating the specific trait, action, or inaction to - if at that exact same moment this individual',
          'were to have been perceived displaying or demonstrating the exact opposite specific trait, action, or inaction to those listed in Column 1, i.e. the way you had wished or hoped they had not done. Include primary, secondary & tertiary benefits or services. Go to each moment you perceived this individual displaying or demonstrating this specific TAI. ',
        ],
        instruction3: [
          'Go to the specific moment where and when you perceived this individual displaying or demonstrating this specific trait, action, or inaction (TAI) that you liked or admired most.',
          'Then write in an abbreviated & overlapping format the benefits or services to you and to the specific other individual or group you perceived the individual was displaying or demonstrating the specific trait, action, or inaction to - if at that exact same moment this individual were to have been perceived displaying or demonstrating the exact opposite specific trait, action, or inaction to those listed in Column 1, i.e. the way you had wished or hoped they had not done',
          'Include primary, secondary & tertiary benefits or services. Go to each moment you perceived this individual displaying or demonstrating this specific TAI. ',
        ],
        goal: '20-50',
        objective:
          'Dissolves Nightmares & The Driving Forces of Fantasies / Philias',
        hint:
          'TR DF SB MF ES CB IJ AY IH GT JF',
        confirmation:
          'Are you now certain that if you perceived the individual in question displaying or demonstrating the exact opposite specific trait, action, or inaction (or anti-TAI) to that listed in Column 1 at that same moment, there would have been just as many benefits, positives, or services as drawbacks, negatives or disservices quantitatively & qualitatively?',
      },
    },
  },
  resentment: {
    self: {
      8: {
        id: 8,
        side: 'B',
        focus: 'self',
        type: 'resentment',
        instruction:
          'Write (in 3-4 words for each) the top three highest priority specific traits, actions, or inactions (TAIs) that you have perceived yourself displaying or demonstrating that you dislike or despise most (content). Make sure they are not derived from hearsay from others and that you can truly pinpoint exactly where and when you perceived them occurring and in what context. Be sure to exclude: synthesized transcendental feelings like unloving or uninspired; serial synonyms, like mean or cruel; vague generalities or labels like bad person, abusive, or bad father or alcoholic; and how you felt as a result of you perceiving yourself displaying or demonstrating the specific TAIs) – like proud or smart.',
        instruction2: [
          'Write (in 3-4 words for each) the top three highest priority specific traits, actions, or inactions (TAIs) that you have perceived yourself displaying or demonstrating that you dislike or despise most (content). Make sure they are not derived from hearsay from others and that you can truly pinpoint exactly where and when you perceived them occurring and in what context.',
          'Be sure to exclude: synthesized transcendental feelings like unloving or uninspired; serial synonyms, like mean or cruel; vague generalities or labels like bad person, abusive, or bad father or alcoholic; and how you felt as a result of you perceiving yourself displaying or demonstrating the specific TAIs) – like proud or smart.',
        ],
        instruction3: [
          'Write (in 3-4 words for each) the top three highest priority specific traits, actions, or inactions (TAIs) that you have perceived yourself displaying or demonstrating that you dislike or despise most (content).',
          'Make sure they are not derived from hearsay from others and that you can truly pinpoint exactly where and when you perceived them occurring and in what context.',
          'Be sure to exclude: synthesized transcendental feelings like unloving or uninspired; serial synonyms, like mean or cruel; vague generalities or labels like bad person, abusive, or bad father or alcoholic; and how you felt as a result of you perceiving yourself displaying or demonstrating the specific TAIs) – like proud or smart.',
        ],
        objective: 'Identifies Most Despised (—) Emotional Charges',
        goal: '3',
        example: 'e.g. Inconsiderate to me',
        hint: 'e.g. Inconsiderate to me',
        confirmation:
          'Are there any more specific human traits, actions or inactions that you most dislike, despise or consider negative or repulsive about yourself?',
      },
      9: {
        id: 9,
        side: 'B',
        focus: 'self',
        type: 'resentment',
        instruction:
          'Go to a moment, where and when you perceived yourself displaying or demonstrating this specific trait, action, or inaction (TAI) that you disliked or despised in yourself most (listed in column 8). Then, write in an abbreviated & overlapping format where, when and to whom you perceive yourself displaying or demonstrating this specific (TAI) to and who perceived you displaying or demonstrating it. Those who perceived you displaying or demonstrating this specific TAI could be, you, the individual you displayed or demonstrated it to or another perceiver.',
        instruction2: [
          'Go to a moment, where and when you perceived yourself displaying or demonstrating this specific trait, action, or inaction (TAI) that you disliked or despised in yourself most (listed in column 8). Then, write in an abbreviated & overlapping format where, when and to whom you perceive yourself displaying or demonstrating this specific (TAI)',
          'to and who perceived you displaying or demonstrating it. Those who perceived you displaying or demonstrating this specific TAI could be, you, the individual you displayed or demonstrated it to or another perceiver.',
        ],
        instruction3: [
          'Go to a moment, where and when you perceived yourself displaying or demonstrating this specific trait, action, or inaction (TAI) that you disliked or despised in yourself most (listed in column 8).',
          'Then, write in an abbreviated & overlapping format where, when and to whom you perceive yourself displaying or demonstrating this specific (TAI) to and who perceived you displaying or demonstrating it.',
          'Those who perceived you displaying or demonstrating this specific TAI could be, you, the individual you displayed or demonstrated it to or another perceiver.',
        ],
        goal: '20-50',
        objective: 'Confirms Laws of Reflection & Transparency',
        hint:
          'wwww',
        confirmation:
          'Are you now <strong>certain</strong> that you perceive yourself displaying or demonstrating this specific trait, action, or inaction (TAI) that you most disliked or despised in yourself – the where, the when, and to whom? Are you now certain that you also have identified who perceived you displaying or demonstrating this TAI?',
      },
      10: {
        id: 10,
        side: 'B',
        focus: 'self',
        type: 'resentment',
        instruction:
          'Go to a moment where and when you perceived yourself displaying or demonstrating this specific trait, action, or inaction that you disliked or despised most. Then write in an abbreviated & overlapping format how this specific trait, action, or inaction, in that moment and from that moment until today was a benefit or service to you in your three highest values and remaining seven areas of life. Include primary, secondary & tertiary benefits. Exclude any self-minimizing answers. Be sure it is the benefits or services to you of you displaying or demonstrating the specific TAI and not the benefits or services to you of you displaying or demonstrating the opposite specific TAI.',
        instruction2: [
          'Go to a moment where and when you perceived yourself displaying or demonstrating this specific trait, action, or inaction that you disliked or despised most. Then write in an abbreviated & overlapping format how this specific trait, action, or inaction, in that moment and from that moment until today was a benefit or service to you in your three highest values and remaining seven areas of life.',
          'Include primary, secondary & tertiary benefits. Exclude any self-minimizing answers. Be sure it is the benefits or services to you of you displaying or demonstrating the specific TAI and not the benefits or services to you of you displaying or demonstrating the opposite specific TAI.',
        ],
        instruction3: [
          'Go to a moment where and when you perceived yourself displaying or demonstrating this specific trait, action, or inaction that you disliked or despised most',
          'Then write in an abbreviated & overlapping format how this specific trait, action, or inaction, in that moment and from that moment until today was a benefit or service to you in your three highest values and remaining seven areas of life. Include primary, secondary & tertiary benefits',
          'Exclude any self-minimizing answers. Be sure it is the benefits or services to you of you displaying or demonstrating the specific TAI and not the benefits or services to you of you displaying or demonstrating the opposite specific TAI.',
        ],
        goal: '20-50',
        objective: 'Dissolves Fear, Other Resentment & Self-Exaggeration',
        hint:
          'TR DF SB MF ES CB IJ AY IH GT JF',
        confirmation:
          'Are you now <strong>certain</strong> that the specific trait, action, or inaction (TAI) that you perceived yourself displaying or demonstrating, that you disliked and despised most listed in Column 8, was also just as equally a benefit or service to you as it was a drawback or disservice? Is it now neither — nor positive?',
      },
      11: {
        id: 11,
        side: 'B',
        focus: 'self',
        type: 'resentment',
        instruction:
          'Go to a moment where and when you perceived yourself displaying or demonstrating this specific trait, action, or inaction (TAI) that you disliked or despised in this yourself most. Then write in an abbreviated & overlapping format how this specific trait, action, or inaction displayed or demonstrated by you was in that moment and until today a benefit or service to those individuals you have displayed or demonstrated this trait, action, or inaction to – or to those individuals perceiving you. Exclude any other– exaggerating answers. Include primary, secondary & tertiary benefits or services. Check in every three answers to assure that you do not go too far and transform your shame into pride. Apply this Column 11 to each moment listed in Column 9 until each moment becomes neither negative nor positive.',
        instruction2: [
          'Go to a moment where and when you perceived yourself displaying or demonstrating this specific trait, action, or inaction (TAI) that you disliked or despised in this yourself most. Then write in an abbreviated & overlapping format how this specific trait, action, or inaction displayed or demonstrated by you was in that moment and until today a benefit or service to those individuals',
          'you have displayed or demonstrated this trait, action, or inaction to – or to those individuals perceiving you. Exclude any other– exaggerating answers. Include primary, secondary & tertiary benefits or services. Check in every three answers to assure that you do not go too far and transform your shame into pride. Apply this Column 11 to each moment listed in Column 9 until each moment becomes neither negative nor positive.',
        ],
        instruction3: [
          'Go to a moment where and when you perceived yourself displaying or demonstrating this specific trait, action, or inaction (TAI) that you disliked or despised in this yourself most.',
          'Then write in an abbreviated & overlapping format how this specific trait, action, or inaction displayed or demonstrated by you was in that moment and until today a benefit or service to those individuals you have displayed or demonstrated this trait, action, or inaction to – or to those individuals perceiving you.',
          'Exclude any other- exaggerating answers. Include primary, secondary & tertiary benefits or services. Check in every three answers to assure that you do not go too far and transform your shame into pride. Apply this Column 11 to each moment listed in Column 9 until each moment becomes neither negative nor positive.',
        ],
        goal: '20-70',
        objective: 'Dissolves Self-Minimization, Guilt & Shame',
        hint:
          'TR DF SB MF ES CB IJ AY IH GT JF',
        confirmation:
          'Are you now <strong>certain</strong> that the specific trait, action, or inaction (TAI) in you listed in Column 8 was also just as equally a benefit or service to those specific other individuals you have displayed or demonstrated them to, or any perceivers, as it was a drawback or disservice to them? Are each now neither — nor positive?',
      },
      12: {
        id: 12,
        side: 'B',
        focus: 'self',
        type: 'resentment',
        instruction:
          'Go to a moment where and when you perceived yourself displaying or demonstrating this specific trait, action, or inaction (TAI) that you disliked or despised in yourself most. Who were you displaying or demonstrating the specific TAI to (vector)? Then write in an abbreviated & overlapping format where and when you perceived yourself displaying or demonstrating the exact opposite specific trait, action, or inaction to the same individual or group you perceived yourself displaying or demonstrating the original specific trait, action, or inaction to and who perceived it. Keep writing overlapping abbreviations until you are certain that the exact opposite specific TAI equals the original specific TAI quantitatively and qualitatively. Do this for each individual that you perceived yourself displaying or demonstrating this specific TAI to.',
        instruction2: [
          'Go to a moment where and when you perceived yourself displaying or demonstrating this specific trait, action, or inaction (TAI) that you disliked or despised in yourself most. Who were you displaying or demonstrating the specific TAI to (vector)? Then write in an abbreviated & overlapping format where and when you perceived yourself displaying or demonstrating the exact opposite specific trait, action, or inaction to the same individual or group',
          'you perceived yourself displaying or demonstrating the original specific trait, action, or inaction to and who perceived it. Keep writing overlapping abbreviations until you are certain that the exact opposite specific TAI equals the original specific TAI quantitatively and qualitatively. Do this for each individual that you perceived yourself displaying or demonstrating this specific TAI to.',
        ],
        instruction3: [
          'Go to a moment where and when you perceived yourself displaying or demonstrating this specific trait, action, or inaction (TAI) that you disliked or despised in yourself most',
          'Who were you displaying or demonstrating the specific TAI to (vector)? Then write in an abbreviated & overlapping format where and when you perceived yourself displaying or demonstrating the exact opposite specific trait, action, or inaction to the same individual or group you perceived yourself displaying or demonstrating the original specific trait, action, or inaction to and who perceived it',
          'Keep writing overlapping abbreviations until you are certain that the exact opposite specific TAI equals the original specific TAI quantitatively and qualitatively. Do this for each individual that you perceived yourself displaying or demonstrating this specific TAI to.',
        ],
        goal: '20-50',
        objective:
          'Dissolves Exaggerated Labels & Enhances Communication & Immunity',
        hint:
          'wwww',
        confirmation:
          'Are you now certain that you perceived yourself displaying or demonstrating the specific trait, action, or inaction and the exact opposite specific trait, action, or inaction (ant-TAI) equally to the same individuals (yourself, others, or group) to the same degree quantitatively and qualitatively? Are you now certain there are no more subjective “Alls” or “Nones” or “Always” or “Nevers”?',
      },
      13: {
        id: 13,
        side: 'B',
        focus: 'self',
        type: 'resentment',
        instruction:
          'Go to the moment where and when you perceived yourself displaying or demonstrating this specific trait, action, or inaction (TAI) that you disliked or despised in yourself most. Where are you? When are you? Get present in the specific moment. Who are you displaying or demonstrating the specific TAI to (vector)? What specifically is the content and context? Write in an abbreviated & overlapping format the initial(s) of those individuals who displayed or demonstrated the exact opposite specific trait, action, or inaction to the same individual or group at the exact same synchronous moment. Make sure they are quantitatively and qualitatively equal and opposite. Exhaust all moments until each of the entangled opposites are synchronized. (One/Many, Male/Female, Close/Distant, Self/Other, Virtual/Real) ',
        instruction2: [
          'Go to the moment where and when you perceived yourself displaying or demonstrating this specific trait, action, or inaction (TAI) that you disliked or despised in yourself most. Where are you? When are you? Get present in the specific moment. Who are you displaying or demonstrating the specific TAI to (vector)? What specifically is the content and context? Write in an abbreviated & overlapping format the initial(s) of those individuals',
          'who displayed or demonstrated the exact opposite specific trait, action, or inaction to the same individual or group at the exact same synchronous moment. Make sure they are quantitatively and qualitatively equal and opposite. Exhaust all moments until each of the entangled opposites are synchronized. (One/Many, Male/Female, Close/Distant, Self/Other, Virtual/Real) ',
        ],
        instruction3: [
          'Go to the moment where and when you perceived yourself displaying or demonstrating this specific trait, action, or inaction (TAI) that you disliked or despised in yourself most.',
          'Where are you? When are you? Get present in the specific moment. Who are you displaying or demonstrating the specific TAI to (vector)? What specifically is the content and context? Write in an abbreviated & overlapping format the initial(s) of those individuals who displayed or demonstrated the exact opposite specific trait, action, or inaction to the same individual or group at the exact same synchronous moment.',
          'Make sure they are quantitatively and qualitatively equal and opposite. Exhaust all moments until each of the entangled opposites are synchronized. (One/Many, Male/Female, Close/Distant, Self/Other, Virtual/Real) ',
        ],
        goal: '18-36',
        objective: 'Demonstrates Synchronicity & Hidden Intelligent Order',
        hint:
          'WWWCCP',
        confirmation:
          'Can you now see that either you or someone else were simultaneously providing the exact opposite human character trait, action or inaction that you demonstrated to a specific other person to synchronously balance out that human character trait, action or inaction you listed in Column 8?',
      },
      14: {
        id: 14,
        side: 'B',
        focus: 'self',
        type: 'resentment',
        instruction:
          'Go to the moment where and when you perceived yourself displaying or demonstrating this specific trait, action, or inaction (TAI) that you disliked or despised in yourself most. Then write in an abbreviated & overlapping format the drawbacks to you and to the specific individual or group that you perceived yourself displaying or demonstrating this specific trait, action, or inaction to - if at that exact same moment you were to have perceived yourself displaying or demonstrating the exact opposite specific trait, action, or inaction to those listed in Column 8, i.e. the way you had wished or hoped you had done. Include primary, secondary & tertiary drawbacks or disservices. Do this for each moment you have perceived yourself displaying or demonstrating this specific TAI.',
        instruction2: [
          'Go to the moment where and when you perceived yourself displaying or demonstrating this specific trait, action, or inaction (TAI) that you disliked or despised in yourself most. Then write in an abbreviated & overlapping format the drawbacks to you and to the specific individual or group that you perceived yourself displaying or demonstrating this specific trait, action, or inaction to - if at that exact same moment you were to have perceived yourself',
          'displaying or demonstrating the exact opposite specific trait, action, or inaction to those listed in Column 8, i.e. the way you had wished or hoped you had done. Include primary, secondary & tertiary drawbacks or disservices. Do this for each moment you have perceived yourself displaying or demonstrating this specific TAI.',
        ],
        instruction3: [
          'Go to the moment where and when you perceived yourself displaying or demonstrating this specific trait, action, or inaction (TAI) that you disliked or despised in yourself most.',
          'Then write in an abbreviated & overlapping format the drawbacks to you and to the specific individual or group that you perceived yourself displaying or demonstrating this specific trait, action, or inaction to - if at that exact same moment you were to have perceived yourself displaying or demonstrating the exact opposite specific trait, action, or inaction to those listed in Column 8, i.e. the way you had wished or hoped you had done.',
          'Include primary, secondary & tertiary drawbacks or disservices. Do this for each moment you have perceived yourself displaying or demonstrating this specific TAI.',
        ],
        goal: '20-50',
        objective:
          'Dissolves Fantasies & The Driving Forces of Nightmares / Phobias',
        hint:
          'TR DF SB MF ES CB IJ AY IH GT JF',
        confirmation:
          'Are you now certain that if you were to have perceived yourself displaying or demonstrating the exact opposite specific trait, action, or inaction (or anti-TAI) to that listed in Column 8 at that same moment, there would have been just as many drawbacks, negatives, or disservices as benefits, positives, or services quantitatively & qualitatively?',
      },
    },
    other: {
      8: {
        id: 8,
        side: 'B',
        focus: 'other',
        type: 'resentment',
        instruction:
          'Write (in 3-4 words for each) the top three highest priority specific traits, actions, or inactions ( AIs) that you perceived this individual above displaying or demonstrating that you dislike or despise most (content). Make sure they are not derived from hearsay from others and that you can truly pinpoint exactly where and when you perceived them occurring and in what context. Be sure to exclude: synthesized transcendental feelings like unloving or uninspired; serial synonyms, like mean or cruel; vague generalities or labels like bad person, abusive, or bad father or alcoholic; and how you felt as a result of you perceiving this individual displaying or demonstrating the specific TAIs) – like humiliated or stupid.',
        instruction2: [
          'Write (in 3-4 words for each) the top three highest priority specific traits, actions, or inactions ( AIs) that you perceived this individual above displaying or demonstrating that you dislike or despise most (content). Make sure they are not derived from hearsay from others and that you can truly pinpoint exactly where and when you perceived them occurring and in what context.',
          'Be sure to exclude: synthesized transcendental feelings like unloving or uninspired; serial synonyms, like mean or cruel; vague generalities or labels like bad person, abusive, or bad father or alcoholic; and how you felt as a result of you perceiving this individual displaying or demonstrating the specific TAIs) – like humiliated or stupid.',
        ],
        instruction3: [
          'Write (in 3-4 words for each) the top three highest priority specific traits, actions, or inactions ( AIs) that you perceived this individual above displaying or demonstrating that you dislike or despise most (content).',
          'Make sure they are not derived from hearsay from others and that you can truly pinpoint exactly where and when you perceived them occurring and in what context.',
          'Be sure to exclude: synthesized transcendental feelings like unloving or uninspired; serial synonyms, like mean or cruel; vague generalities or labels like bad person, abusive, or bad father or alcoholic; and how you felt as a result of you perceiving this individual displaying or demonstrating the specific TAIs) – like humiliated or stupid.',
        ],
        goal: '3',
        example: 'e.g. Inconsiderate to me',
        objective: 'Identifies Negative Emotional Charge',
        hint: 'Ex: Inconsiderate to me',
        confirmation:
          'Are you now <strong>certain</strong> that you have listed the top three highest priority specific traits, actions, or inactions (TAIs) that you perceived this individual above was displaying or demonstrating that you most dislike, despise, look down on, resent, or consider negative or repulsive?',
      },
      9: {
        id: 9,
        side: 'B',
        focus: 'other',
        type: 'resentment',
        instruction:
          'Go to a specific moment, where and when you perceived yourself displaying or demonstrating this same or similar specific trait, action, or inaction (TAI) that you disliked or despised in this individual most (listed in column 8). Then, write in an abbreviated & overlapping format <strong>where</strong>, <strong>when</strong> and to <strong>whom</strong> you have perceived yourself displaying or demonstrating this same or similar specific (TAI) to and who perceived you displaying or demonstrating it until you can state with certainty that you own this trait, action, or inaction 100% as much as you have perceived it in them both quantitatively / qualitatively. Consider all 7 areas of your life from past to present. Those who perceived you displaying or demonstrating this specific TAI could be you, the individual you displayed or demonstrated it to, or another perceiver. ',
        instruction2: [
          'Go to a specific moment, where and when you perceived yourself displaying or demonstrating this same or similar specific trait, action, or inaction (TAI) that you disliked or despised in this individual most (listed in column 8). Then, write in an abbreviated & overlapping format <strong>where</strong>, <strong>when</strong> and to <strong>whom</strong> you have perceived yourself displaying or demonstrating this same or similar specific (TAI) to',
          'and who perceived you displaying or demonstrating it until you can state with certainty that you own this trait, action, or inaction 100% as much as you have perceived it in them both quantitatively / qualitatively. Consider all 7 areas of your life from past to present. Those who perceived you displaying or demonstrating this specific TAI could be you, the individual you displayed or demonstrated it to, or another perceiver. ',
        ],
        instruction3: [
          'Go to a specific moment, where and when you perceived yourself displaying or demonstrating this same or similar specific trait, action, or inaction (TAI) that you disliked or despised in this individual most (listed in column 8).',
          'Then, write in an abbreviated & overlapping format <strong>where</strong>, <strong>when</strong> and to <strong>whom</strong> you have perceived yourself displaying or demonstrating this same or similar specific (TAI) to and who perceived you displaying or demonstrating it until you can state with certainty that you own this trait, action, or inaction 100% as much as you have perceived it in them both quantitatively / qualitatively.',
          'Consider all 7 areas of your life from past to present. Those who perceived you displaying or demonstrating this specific TAI could be you, the individual you displayed or demonstrated it to, or another perceiver. ',
        ],
        goal: '20-50',
        objective: 'Confirms Laws of Reflection & Transparency',
        hint:
          'wwww',
        confirmation:
          'Are you now <strong>certain</strong> that you perceive yourself displaying or demonstrating this same or similar specific trait, action, or inaction (TAI) that you disliked or despised most in this individual 100% to the same degree quantitatively and qualitatively?',
      },
      10: {
        id: 10,
        side: 'B',
        focus: 'other',
        type: 'resentment',
        instruction:
          'Go to the specific moment where and when you perceived this individual displaying or demonstrating this specific trait, action, or inaction that you disliked or despised most. Then write in an abbreviated & overlapping format how this specific trait, action, or inaction, in that moment and from that moment until today was a benefit or service to you (or another individual and you) in your three highest values and remaining seven areas of life. Include primary, secondary & tertiary benefits. Exclude any self- exaggerating answers. Be sure it is the benefits or services to you of them displaying or demonstrating the specific TAI and not the benefits or services to you of you doing the opposite or not displaying or demonstrating the specific TAI.',
        instruction2: [
          'Go to the specific moment where and when you perceived this individual displaying or demonstrating this specific trait, action, or inaction that you disliked or despised most. Then write in an abbreviated & overlapping format how this specific trait, action, or inaction, in that moment and from that moment until today was a benefit or service to you (or another individual and you) in your three highest values and remaining seven areas of life.',
          'Include primary, secondary & tertiary benefits. Exclude any self- exaggerating answers. Be sure it is the benefits or services to you of them displaying or demonstrating the specific TAI and not the benefits or services to you of you doing the opposite or not displaying or demonstrating the specific TAI.',
        ],
        instruction3: [
          'Go to the specific moment where and when you perceived this individual displaying or demonstrating this specific trait, action, or inaction that you disliked or despised most.',
          'Then write in an abbreviated & overlapping format how this specific trait, action, or inaction, in that moment and from that moment until today was a benefit or service to you (or another individual and you) in your three highest values and remaining seven areas of life.',
          'Include primary, secondary & tertiary benefits. Exclude any self- exaggerating answers. Be sure it is the benefits or services to you of them displaying or demonstrating the specific TAI and not the benefits or services to you of you doing the opposite or not displaying or demonstrating the specific TAI.',
        ],
        goal: '20-50',
        objective: 'Dissolves Fear, Other Resentment & Self-Exaggeration',
        hint:
          'TR DF SB MF ES CB IJ AY IH GT JF',
        confirmation:
          'Are you now <strong>certain</strong> that the specific trait, action, or inaction (TAI) that you perceived this individual displaying or demonstrating, that you disliked and despised most listed in Column 8, was also just as equally a benefit or service to you (or another individual and you) as it was a drawback or disservice? Is it now neither — nor positive?',
      },
      11: {
        id: 11,
        side: 'B',
        focus: 'other',
        type: 'resentment',
        instruction:
          'Go to the specific moment where and when you perceived yourself displaying or demonstrating this same or similar specific trait, action, or inaction (TAI) that you disliked or despised in this individual most. Then write in an abbreviated & overlapping format how this specific trait, action, or inaction that you perceived yourself displaying or demonstrating was in that moment and until today a benefit or service to those you perceived yourself displaying or demonstrating this trait, action, or inaction to – or to those perceiving you. Exclude any other–exaggerating answers. Include primary, secondary & tertiary benefits or services. Check in every three answers to assure that you do not go too far and transform your shame into pride. Apply this Column 11 to each moment listed in Column 9 until each moment becomes neither negative nor positive.',
        instruction2: [
          'Go to the specific moment where and when you perceived yourself displaying or demonstrating this same or similar specific trait, action, or inaction (TAI) that you disliked or despised in this individual most. Then write in an abbreviated & overlapping format how this specific trait, action, or inaction that you perceived yourself displaying or demonstrating was in that moment and until today a benefit or service to those you perceived yourself ',
          'displaying or demonstrating this trait, action, or inaction to – or to those perceiving you. Exclude any other–exaggerating answers. Include primary, secondary & tertiary benefits or services. Check in every three answers to assure that you do not go too far and transform your shame into pride. Apply this Column 11 to each moment listed in Column 9 until each moment becomes neither negative nor positive.',
        ],
        instruction3: [
          'Go to the specific moment where and when you perceived yourself displaying or demonstrating this same or similar specific trait, action, or inaction (TAI) that you disliked or despised in this individual most.',
          'Then write in an abbreviated & overlapping format how this specific trait, action, or inaction that you perceived yourself displaying or demonstrating was in that moment and until today a benefit or service to those you perceived yourself displaying or demonstrating this trait, action, or inaction to – or to those perceiving you.',
          'Exclude any other–exaggerating answers. Include primary, secondary & tertiary benefits or services. Check in every three answers to assure that you do not go too far and transform your shame into pride. Apply this Column 11 to each moment listed in Column 9 until each moment becomes neither negative nor positive.',
        ],
        goal: '20-70',
        objective: 'Dissolves Self-Minimization, Guilt & Shame',
        hint:
          'TR DF SB MF ES CB IJ AY IH GT JF',
        confirmation:
          'Are you now <strong>certain</strong> that the specific trait, action, or inaction (TAI) in this individual you listed in Column 8 and reflected and confirmed equally in yourself in Column 9 was also just as equally a benefit or service to those specific individuals you have displayed or demonstrated them to - or to those perceiving you - as it was a drawback or disservice to them? Are each now neither — nor positive?',
      },
      12: {
        id: 12,
        side: 'B',
        focus: 'other',
        type: 'resentment',
        instruction:
          'Go to the specific moment where and when you perceived this individual displaying or demonstrating this specific trait, action, or inaction (TAI) that you disliked or despised most. Who did you perceive them displaying or demonstrating the specific TAI to (vector)? Then write in an abbreviated & overlapping format where and when you perceived this individual at any time displaying or demonstrating the exact opposite specific trait, action, or inaction to the same individual or group they displayed or demonstrated the original specific trait, action, or inaction to and who perceived it. Keep writing overlapping abbreviations until you are certain that the exact opposite specific TAI equals the original specific TAI quantitatively and qualitatively. Do this for each individual that you perceived them displaying or demonstrating this specific TAI to.',
        instruction2: [
          'Go to the specific moment where and when you perceived this individual displaying or demonstrating this specific trait, action, or inaction (TAI) that you disliked or despised most. Who did you perceive them displaying or demonstrating the specific TAI to (vector)? Then write in an abbreviated & overlapping format where and when you perceived this individual at any time displaying or demonstrating the exact opposite specific trait, action, or inaction to the same individual or group',
          'they displayed or demonstrated the original specific trait, action, or inaction to and who perceived it. Keep writing overlapping abbreviations until you are certain that the exact opposite specific TAI equals the original specific TAI quantitatively and qualitatively. Do this for each individual that you perceived them displaying or demonstrating this specific TAI to.',
        ],
        instruction3: [
          'Go to the specific moment where and when you perceived this individual displaying or demonstrating this specific trait, action, or inaction (TAI) that you disliked or despised most.',
          'Who did you perceive them displaying or demonstrating the specific TAI to (vector)? Then write in an abbreviated & overlapping format where and when you perceived this individual at any time displaying or demonstrating the exact opposite specific trait, action, or inaction to the same individual or group they displayed or demonstrated the original specific trait, action, or inaction to and who perceived it.',
          'Keep writing overlapping abbreviations until you are certain that the exact opposite specific TAI equals the original specific TAI quantitatively and qualitatively. Do this for each individual that you perceived them displaying or demonstrating this specific TAI to.',
        ],
        goal: '20-50',
        objective:
          'Dissolves Exaggerated Labels & Enhances Communication & Immunity',
        hint:
          'wwww',
        confirmation:
          'Are you now <strong>certain</strong> that this individual above has displayed or demonstrated the specific trait, action, or inaction and the exact opposite specific trait, action, or inaction (ant-TAI) equally to the same individuals (yourself, others or group) to the same degree quantitatively and qualitatively? Are you now certain there are no more subjective “Alls” or “Nones” or “Always” or “Nevers”?',
      },
      13: {
        id: 13,
        side: 'B',
        focus: 'other',
        type: 'resentment',
        instruction:
          'Go to the specific moment where and when you perceived this individual displaying or demonstrating this specific trait, action, or inaction (TAI) that you disliked or despised most. Where are you? When are you? Get present in the moment. Who is this individual displaying or demonstrating the specific TAI to (vector)? What specifically is the content and context? Write in an abbreviated & overlapping format the initial(s) of those individuals who displayed or demonstrated the exact opposite specific trait, action, or inaction to the same individual or group at the exact same synchronous moment. Make sure they are quantitatively and qualitatively equal and opposite. Exhaust all moments until each of the entangled opposites are synchronized. (One/Many, Male/Female, Close/Distant, Self/Other, Virtual/Real)',
        instruction2: [
          'Go to the specific moment where and when you perceived this individual displaying or demonstrating this specific trait, action, or inaction (TAI) that you disliked or despised most. Where are you? When are you? Get present in the moment. Who is this individual displaying or demonstrating the specific TAI to (vector)? What specifically is the content and context? Write in an abbreviated & overlapping format the initial(s) of those individuals',
          'who displayed or demonstrated the exact opposite specific trait, action, or inaction to the same individual or group at the exact same synchronous moment. Make sure they are quantitatively and qualitatively equal and opposite. Exhaust all moments until each of the entangled opposites are synchronized. (One/Many, Male/Female, Close/Distant, Self/Other, Virtual/Real)',
        ],
        instruction3: [
          'Go to the specific moment where and when you perceived this individual displaying or demonstrating this specific trait, action, or inaction (TAI) that you disliked or despised most.',
          'Where are you? When are you? Get present in the moment. Who is this individual displaying or demonstrating the specific TAI to (vector)? What specifically is the content and context? Write in an abbreviated & overlapping format the initial(s) of those individuals who displayed or demonstrated the exact opposite specific trait, action, or inaction to the same individual or group at the exact same synchronous moment.',
          'Make sure they are quantitatively and qualitatively equal and opposite. Exhaust all moments until each of the entangled opposites are synchronized. (One/Many, Male/Female, Close/Distant, Self/Other, Virtual/Real)',
        ],
        goal: '18-36',
        objective: 'Demonstrates Synchronicity & Hidden Intelligent Order',
        hint:
          'WWWCCP',
        confirmation:
          'Are you now <strong>certain</strong> that either you or someone else were simul- taneously displaying or demonstrating the exact opposite specific trait, action, or inaction that this individual displayed or demonstrated to you or a specific other individual in order to synchronously balance out that specific character trait, action, or inaction you listed in Column 8 quantitatively & qualitatively?',
      },
      14: {
        id: 14,
        side: 'B',
        focus: 'other',
        type: 'resentment',
        instruction:
          'Go to the specific moment where and when you perceived this individual displaying or demonstrating this specific trait, action, or inaction (TAI) that you disliked or despised most. Then write in an abbreviated & overlapping format the drawbacks or disservices to you and to the specific individual or group you perceived the individual displaying or demonstrating the specific trait, action, or inaction to - if at that exact same moment this individual were to have been perceived displaying or demonstrating the exact opposite specific trait, action, or inaction to those listed in Column 8, i.e. the way you had wished or hoped they had done. Include primary, secondary & tertiary drawbacks or disservices. Go to each moment you perceived this individual displaying or demonstrating this specific TAI.',
        instruction2: [
          'Go to the specific moment where and when you perceived this individual displaying or demonstrating this specific trait, action, or inaction (TAI) that you disliked or despised most. Then write in an abbreviated & overlapping format the drawbacks or disservices to you and to the specific individual or group you perceived the individual displaying or demonstrating the specific trait, action, or inaction to - if at that exact same moment this individual',
          'were to have been perceived displaying or demonstrating the exact opposite specific trait, action, or inaction to those listed in Column 8, i.e. the way you had wished or hoped they had done. Include primary, secondary & tertiary drawbacks or disservices. Go to each moment you perceived this individual displaying or demonstrating this specific TAI.',
        ],
        instruction3: [
          'Go to the specific moment where and when you perceived this individual displaying or demonstrating this specific trait, action, or inaction (TAI) that you disliked or despised most.',
          'Then write in an abbreviated & overlapping format the drawbacks or disservices to you and to the specific individual or group you perceived the individual displaying or demonstrating the specific trait, action, or inaction to - if at that exact same moment this individual were to have been perceived displaying or demonstrating the exact opposite specific trait, action, or inaction to those listed in Column 8, i.e. the way you had wished or hoped they had done.',
          'Include primary, secondary & tertiary drawbacks or disservices. Go to each moment you perceived this individual displaying or demonstrating this specific TAI.',
        ],
        goal: '20-50',
        objective:
          'Dissolves Fantasies & The Driving Forces of Nightmares / Phobias',
        hint:
          'TR DF SB MF ES CB IJ AY IH GT JF',
        confirmation:
          'Are you now <strong>certain</strong> that if you perceived the individual in question displaying or demo strating the exact opposite specific trait, action, or inaction (or anti-TAI) to that listed in Column 8 at that same moment, there would have been just as many drawbacks, negatives, or disservices as benefits, positives or services quantitatively & qualitatively?',
      },
    },
  },
}

export default panels
