import { KIOE } from '@/utility/utility'

export default function useToast() {
  const { $toast } = useNuxtApp()
  function toast(request: ToastRequest) {
    try {
      if (process.client) {
        if (KIOE(request, 'context'))
          $toast[request.type](`${request.context} | ${request.message}`)
        else
          $toast[request.type](request.message)
      }
    }
    catch (e: any) {
      throw new Error(`useToast | toast | error processing toast - ${e.message}`)
    }
  }
  return {
    toast,
  }
}
